// LearningPathCard.jsx

import React from "react"
import { useNavigate } from "react-router-dom"
import { UserCircleIcon, ClipboardDocumentListIcon } from "@heroicons/react/20/solid"
import ActionDropdown from "./actionDropdown"

const LearningPathCard = ({ learningPath, onEdit, onDelete }) => {
  const navigate = useNavigate()

  const handleCardClick = () => {
    navigate(`/learningPaths/${learningPath.learningPathId}`)
  }

  return (
    <div
      className="w-[280px] m-[10px] rounded-[5px] relative border border-transparent hover:border-blue-500 cursor-pointer"
      onClick={handleCardClick}>
      <h2 className="sr-only">Learning Path Summary</h2>
      <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
        <dl className="flex flex-wrap">
          <div className="flex-auto pl-6 pt-6">
            <dt className="text-sm font-semibold text-gray-900">{learningPath.displayName}</dt>
          </div>
          <div className="flex-none self-end px-6 pt-4">
            <ActionDropdown
              actions={[
                { label: "Edit", onClick: () => onEdit(learningPath) },
                { label: "Delete", onClick: () => onDelete(learningPath.learningPathId) },
              ]}
            />
          </div>
          <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-200 px-6 pt-6">
            <dt className="flex-none">
              <UserCircleIcon aria-hidden="true" className="h-6 w-6 text-gray-400" />
            </dt>
            <dd className="text-sm text-gray-500">Created by: {learningPath.createdBy || "Unknown"}</dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <ClipboardDocumentListIcon aria-hidden="true" className="h-6 w-6 text-gray-400" />
            </dt>
            <dd className="text-sm text-gray-500">
              Modules: {learningPath.moduleIds ? learningPath.moduleIds.length : 0}
            </dd>
          </div>
        </dl>
        <div className="mt-6 border-t border-gray-200 px-6 py-6">
          <p className="text-sm text-gray-700">{learningPath.description || "No description available."}</p>
        </div>
      </div>
    </div>
  )
}

export default LearningPathCard

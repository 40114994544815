import { Container } from "./Container"
import SOC2LOGO from "../images/SOC2/21972-312_SOC_NonCPA.png"
import { useNavigate } from "react-router-dom";
import { ArrowRightIcon } from '@heroicons/react/20/solid'
import CyclingTitle from "../../components/TextComponents/CyclingTitle"


export function Hero() {
  const navigate = useNavigate()
  return (
    <Container className="pb-16 pt-20 text-center flex-1 lg:pt-32">
      <h1 className="mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-900 sm:text-7xl">
        <CyclingTitle></CyclingTitle>
      </h1>
      <p className="mx-auto mt-6 max-w-2xl text-lg tracking-tight text-slate-700">
        Next-gen sales training for customer facing teams powered by AI
      </p>
      <div className="mt-10 flex justify-center gap-x-6">
        <button
          onClick={() => navigate("/waitlistRequestPage")}
          type="button"
          className="inline-flex items-center gap-x-2 rounded-md bg-pareBlue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#1c34cc] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pareBlue"
        >
          Request access today
              <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
        </button>
        <button
          onClick={() => window.location.href = "mailto:alex@pare-ai.com"}
          type="button"
          className="inline-flex items-center gap-x-2 rounded-md bg-gray-300 px-3.5 py-2.5 text-sm font-semibold text-black shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-300"
        >
          Get in touch
              <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
        </button>
      </div>
      <div className="mt-20">
        <div className="text-slate-500 text-sm">SOC 2 Certified</div>
        <img className="mx-auto h-10 w-auto" src={SOC2LOGO} alt={"pare_logo"} />
      </div>
    </Container>
  )
}

// src/contexts/NotificationContext.js

import React, { createContext, useState } from "react"
import ReactDOM from "react-dom"
import Notification from "../components/Global/successNotificationToast"

export const NotificationContext = createContext()

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({
    show: false,
    type: "success", // 'success' or 'error'
    title: "",
    message: "",
  })

  const triggerNotification = (type, title, message) => {
    setNotification({
      show: true,
      type,
      title,
      message,
    })
  }

  const hideNotification = () => {
    setNotification(prev => ({ ...prev, show: false }))
  }

  return (
    <NotificationContext.Provider value={{ triggerNotification }}>
      {children}
      {/* Render the Notification component using a Portal */}
      {ReactDOM.createPortal(
        <Notification
          show={notification.show}
          setShow={hideNotification}
          type={notification.type}
          title={notification.title}
          message={notification.message}
        />,
        document.body,
      )}
    </NotificationContext.Provider>
  )
}

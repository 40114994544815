import React, { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import EmptyState from "../../components/Global/emptyState"
import ActivityBox from "../../components/ActivityBox/ActivityBox"
import WarningPopUp from "../../components/PopUps/WarningPopUp"
import LoadingPopUp from "../../components/PopUps/LoadingPopUp"
import SingleInputPopUp from "../../components/PopUps/SingleInputPopUp"
import "./ManagerDevelopmentPage.css"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { toJS } from "mobx"
import ManagerDevPageSkeleton from "../../components/ManagerDevPage/skeletons"
import { ArrowPathIcon } from '@heroicons/react/20/solid'
import { NotificationContext } from "../../contexts/notificationContext"
import { FlagIcon } from "@heroicons/react/20/solid"
import { UpdateModule } from "../../api/routes"
import CreateButton from "../../components/Buttons/createButton"



const ManagerDevelopmentPage = () => {
  const { triggerNotification } = useContext(NotificationContext)
  const { moduleListStore, userStore } = useStores()
  const activityList = moduleListStore.userModules ?.moduleList ? toJS(moduleListStore.userModules.moduleList) : []
  const navigate = useNavigate()
  const [pageLoading, setPageLoading] = useState(true)
  const [searchBarInput, setSearchBarInput] = useState("")
  const [deletePopUpMenu, setDeletePopUpMenu] = useState(false)
  const [renamePopUpMenu, setRenamePopUpMenu] = useState(false)
  const [renameInput, setRenameInput] = useState("")
  const [loading, setLoading] = useState(false)
  const [popUpMenuDetails, setPopUpMenuDetails] = useState(null)
  const activityNameList = useState(activityList.map(x => x.testName.toLowerCase()))

  const refresh = async () => {
    setPageLoading(true)
    if (userStore.user.type === "SDR" || userStore.user.type === "AE") {
      await moduleListStore.getAssignedModules(userStore.user.company, userStore.user.userId)
    } else {
      await moduleListStore.getManagerModules(userStore.user.type, userStore.user.company, userStore.user.userId)
    }
    setPageLoading(false)
  }

  useEffect(() => {
    const fetchModules = async () => {
      if (!moduleListStore.userModules || moduleListStore.checkForInProgressModules()) {
        if (userStore.user.type === "SDR" || userStore.user.type === "AE") {
          await moduleListStore.getAssignedModules(userStore.user.company, userStore.user.userId)
        } else {
          await moduleListStore.getManagerModules(userStore.user.type, userStore.user.company, userStore.user.userId)
        }
      }
      setPageLoading(false)
    }

    fetchModules()
  }, [])

  const handleSearchBarChange = e => {
    e.preventDefault()
    setSearchBarInput(e.target.value)
  }

  const openMenuPopUp = convoObj => {
    setPopUpMenuDetails(convoObj)
    if (convoObj.popUpType === "delete") {
      setDeletePopUpMenu(true)
    } else if (convoObj.popUpType === "rename") {
      setRenamePopUpMenu(true)
    }
  }
  const closeMenuPopUp = () => {
    if (popUpMenuDetails.popUpType === "delete") {
      setDeletePopUpMenu(false)
    } else if (popUpMenuDetails.popUpType === "rename") {
      setRenamePopUpMenu(false)
    }
    setPopUpMenuDetails(null)
  }

  const handleRenameInputChange = event => {
    setRenameInput(event.target.value)
  }

  const renameActivity = async () => {
    if (renameInput.trim() === "") {
      triggerNotification("error", "Error renaming module", `Rename the conversation to a valid name`)
    } else if (activityNameList.includes(renameInput.toLowerCase().trim())) {
      triggerNotification("error", "Error renaming module", `Module name already exists`)
    } else {
      const res = await UpdateModule(popUpMenuDetails.manager_id, popUpMenuDetails.activity_id, {
        user_id: userStore.user.userId,
        testName: renameInput.trim()
      })
      if (res.kind === 'ok') {
        moduleListStore.updateModuleField(popUpMenuDetails.activity_id, "testName", renameInput.trim())
        triggerNotification("sucesss", "Module Update successful!", `The module name was successfully updated!`)
      } else {
        triggerNotification("error", 'Module Update Error', res.error.message)

      }
      closeMenuPopUp()
      setRenameInput("")
    }
  }
  const deleteActivity = async () => {
    const res = await UpdateModule(popUpMenuDetails.manager_id, popUpMenuDetails.activity_id, {
      user_id: userStore.user.userId,
      deleted: 'Y'
    })
    if (res.kind === 'ok') {
      moduleListStore.removeModule(popUpMenuDetails.activity_id)
      triggerNotification("success", "Successully deleted module!", `Successfully deleted module: ${popUpMenuDetails.testName}`)
    } else {
      triggerNotification("error", 'Module Delete Error', res.error.message)

    }
    closeMenuPopUp()
  }

  const handleCreateNewModuleClick = () => {
    navigate("/activityCreationPage")
  }

  const RenamePopUpMenuDiv = renamePopUpMenu ? (
    <div className={`frostedBackground is-visible`}>
      {loading ? (
        <LoadingPopUp header="Renaming Module..."></LoadingPopUp>
      ) : (
          <SingleInputPopUp
            header={`Rename "${popUpMenuDetails.testName}"`}
            body={`Enter new assignment title to change name`}
            leftButtonFunction={closeMenuPopUp}
            leftButtonLabel="Cancel"
            rightButtonFunction={renameActivity}
            rightButtonLabel="Rename"
            handleInputChange={handleRenameInputChange}></SingleInputPopUp>
        )}
    </div>
  ) : null

  const DeletePopUpMenuDiv = deletePopUpMenu ? (
    <div className={`frostedBackground is-visible`}>
      {loading ? (
        <LoadingPopUp header="Deleting Module..."></LoadingPopUp>
      ) : (
          <WarningPopUp
            header={`Delete "${popUpMenuDetails.testName}" ?`}
            body={`Are you sure you want to delete this module?`}
            leftButtonLabel="Cancel"
            leftButtonFunction={closeMenuPopUp}
            rightButtonFunction={deleteActivity}
            rightButtonLabel="Delete Module"></WarningPopUp>
        )}
    </div>
  ) : null

  if (pageLoading) {
    return <ManagerDevPageSkeleton />
  }

  return (
    <div class="w-full h-full flex flex-col px-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <FlagIcon aria-hidden="true" className="h-5 w-4 text-pareBlue mr-1" />
          <h1 class="text-base font-semibold leading-6 text-gray-900">Module Library</h1>
          <p class="mt-2 text-sm text-gray-700">View, edit, and create role play modules</p>
        </div>
        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <CreateButton buttonLabel="Create Module" buttonFunction={handleCreateNewModuleClick}></CreateButton>
        </div>
      </div>
      <div class="content-center align-items flex gap-5">
        <input
          type="text"
          placeholder="Search modules..."
          className="mb-8 mt-4 rounded border-grey-200 w-[500px]"
          value={searchBarInput}
          onChange={handleSearchBarChange}></input>
        <button class="w-7 h-7 bg-coverborder-none cursor-pointer hover:opacity-80 mb-7 mt-6" onClick={refresh}>
          <ArrowPathIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
        </button>
      </div>
      {activityList.length == 0 ? (
        <div className="w-full h-[60%] mt-20 flex flex-col items-center justify-center">
          <EmptyState
            header="No Existing Modules"
            subHeader="Build, design, and publish a module for your team when you’re ready"
            buttonLabel="Create module"
            buttonFunction={handleCreateNewModuleClick}
            icon="icon"></EmptyState>
        </div>
      ) : (
          <div className="w-full h-full rounded-md shadow-sm flex flex-row flex-wrap">
            {searchBarInput.length > 0
              ? activityList
                .filter(x => x.testName.match(new RegExp(`^${searchBarInput}.*`, "i")))
                .map((x, i) => (
                  <div key={i}>
                    <ActivityBox type={"FlexBox"} activityInfo={x} handleMenuPopUp={openMenuPopUp}></ActivityBox>
                  </div>
                ))
              : activityList.map((x, i) => (
                <div key={i}>
                  <ActivityBox type={"FlexBox"} activityInfo={x} handleMenuPopUp={openMenuPopUp}></ActivityBox>
                </div>
              ))}
          </div>
        )}
      {RenamePopUpMenuDiv}
      {DeletePopUpMenuDiv}
    </div>
  )
}

export default observer(ManagerDevelopmentPage)

import { useState } from "react"
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react"
import DropdownSelector from "../LearningCenter/dropdown"

const ManagerSelectPopup = ({ currentUserName, currentManagerName, managerNames, onConfirm, onClose }) => {
  const [selectedManager, setSelectedManager] = useState("") // Start with no selection

  const handleManagerChange = event => {
    setSelectedManager(event.target.value)
  }

  return (
    <Dialog open={true} onClose={onClose || (() => {})} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95">
            <div>
              <div className="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                  Update Manager for {currentUserName}
                </DialogTitle>
                <div className="mt-2 text-sm text-gray-500">
                  <p>
                    {currentUserName}'s current manager is{" "}
                    {currentManagerName !== "UNDEFINED" ? currentManagerName : "not set"}
                  </p>
                </div>
                <div className="mt-4">
                  <DropdownSelector
                    label="Select New Manager"
                    options={[
                      { label: "Please select a manager", value: "", disabled: true }, // Disabled default option
                      ...managerNames
                        .filter(manager => manager !== currentManagerName)
                        .map(manager => ({ label: manager, value: manager })),
                    ]}
                    value={selectedManager}
                    onChange={handleManagerChange}
                  />
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <button
                type="button"
                onClick={() => {
                  if (selectedManager) {
                    onConfirm(selectedManager) // Pass only if a valid manager is selected
                  } else {
                    alert("Please select a valid manager.") // Guide the user
                  }
                  if (onClose) onClose()
                }}
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Confirm
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}

export default ManagerSelectPopup

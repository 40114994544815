import { types, flow, SnapshotIn, Instance } from "mobx-state-tree"
import { GetLearningPaths, CreateLearningPath, UpdateLearningPath, DeleteLearningPath } from "../../api/routes"

// Define the LearningPathFieldsModel
const LearningPathFieldsModel = types.model("LearningPathFields", {
  learningPathId: types.identifier, // Unique identifier
  companyId: types.string,
  displayName: types.string,
  description: types.string,
  createdBy: types.string,
  moduleIds: types.array(types.string),
  completionCriteria: types.optional(types.frozen(), null),
})

// Define the LearningPathListFieldsModel
const LearningPathListFieldsModel = types.model("LearningPathsFields", {
  learningPathList: types.array(LearningPathFieldsModel),
})

export type LearningPathFieldsSnapshotIn = SnapshotIn<typeof LearningPathListFieldsModel>

// Define the LearningPathListStoreModel
export const LearningPathListStoreModel = types
  .model("LearningPathStore", {
    userLearningPaths: types.maybeNull(LearningPathListFieldsModel),
  })
  .actions(self => ({
    // Fetch learning paths from the server
    getLearningPaths: flow(function* getLearningPaths(companyId, userId) {
      try {
        const response = yield GetLearningPaths(companyId, userId)

        if (response.kind === "ok") {
          self.userLearningPaths = LearningPathListFieldsModel.create({
            learningPathList: response.data.map(lp => LearningPathFieldsModel.create(lp)),
          })
          return true
        }
      } catch (e) {
        console.error("Error fetching learning paths:", e)
        return false
      }
      return false
    }),

    // Create a new learning path
    createLearningPath: flow(function* createLearningPath(learningPath) {
      try {
        const response = yield CreateLearningPath(learningPath)
        if (response.kind === "ok") {
          const newLearningPath = LearningPathFieldsModel.create(response.data)
          if (!self.userLearningPaths) {
            self.userLearningPaths = LearningPathListFieldsModel.create({
              learningPathList: [newLearningPath],
            })
          } else {
            self.userLearningPaths.learningPathList.push(newLearningPath)
          }
          return true
        }
      } catch (e) {
        console.error("Error creating learning path:", e)
        return false
      }
      return false
    }),

    // Update an existing learning path
    updateLearningPath: flow(function* updateLearningPath(companyId, learningPathId, updatedFields) {
      try {
        const response = yield UpdateLearningPath(companyId, learningPathId, updatedFields)
        if (response.kind === "ok") {
          // Update the learning path in the store
          const index = self.userLearningPaths.learningPathList.findIndex(lp => lp.learningPathId === learningPathId)
          if (index > -1) {
            self.userLearningPaths.learningPathList[index] = LearningPathFieldsModel.create(response.data)
          }
          return true
        }
      } catch (e) {
        console.error("Error updating learning path:", e)
        return false
      }
      return false
    }),

    // Delete a learning path
    deleteLearningPath: flow(function* deleteLearningPath(companyId, learningPathId, userId) {
      try {
        const response = yield DeleteLearningPath(companyId, learningPathId, userId)
        if (response.kind === "ok") {
          // Remove the learning path from the store
          const index = self.userLearningPaths.learningPathList.findIndex(lp => lp.learningPathId === learningPathId)
          if (index > -1) {
            self.userLearningPaths.learningPathList.splice(index, 1)
          }
          return true
        }
      } catch (e) {
        console.error("Error deleting learning path:", e)
        return false
      }
      return false
    }),
  }))
  .views(self => ({
    // Get a specific learning path by ID
    getLearningPathById(learningPathId) {
      if (!self.userLearningPaths) return null
      return self.userLearningPaths.learningPathList.find(lp => lp.learningPathId === learningPathId) || null
    },

    // Get all learning paths
    getAllLearningPaths() {
      if (!self.userLearningPaths) return []
      return self.userLearningPaths.learningPathList
    },
  }))

export type LearningPathStore = Instance<typeof LearningPathListStoreModel>

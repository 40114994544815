import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Auth, API } from "aws-amplify"
import WarningPopUp from "../../components/PopUps/WarningPopUp"
import LoadingPopUp from "../../components/PopUps/LoadingPopUp"
import SingleInputPopUp from "../../components/PopUps/SingleInputPopUp"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../@shadcn_components/ui/breadcrumb"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { toJS } from "mobx";
import ManagerDevPageSkeleton from "../../components/ManagerDevPage/skeletons"
import { ArrowPathIcon } from '@heroicons/react/20/solid'
import PersonaBox from "../../components/PersonaBox/PersonaBox"
import PersonaCreationPopUp from "../../components/PersonaLibrary/PersonaCreationPopUp"
import PersonaPreview from "../../components/PersonaLibrary/PersonaPreview"

const PersonaLibraryPage = () => {
  const { moduleListStore, userStore } = useStores()
  const activityList = moduleListStore.userModules ?.moduleList ? toJS(moduleListStore.userModules.moduleList) : []
  const [pageLoading, setPageLoading] = useState(true)
  const [searchBarInput, setSearchBarInput] = useState("")
  const [showCreationPopUp, setShowCreationPopUp] = useState(false)
  const [openPersonaPreview, setOpenPersonaPreview] = useState(false)
  const [personaPreviewInfo, setPersonaPreviewInfo] = useState({})

  useEffect(() => {
    const fetchModules = async () => {
      if (!moduleListStore.userModules) {
        if (userStore.user.type === "SDR" || userStore.user.type === "AE") {
          await moduleListStore.getAssignedModules(userStore.user.company, userStore.user.userId)
        } else {
          await moduleListStore.getManagerModules(userStore.user.type, userStore.user.company, userStore.user.userId)
        }
      }
      setPageLoading(false)
    }

    fetchModules()
  }, [])

  const handleSearchBarChange = e => {
    e.preventDefault()
    setSearchBarInput(e.target.value)
  }


  const handleCreateNewPersonaClick = () => {
    setShowCreationPopUp(true)
  }



  const creationPopUp = showCreationPopUp ? (
    <PersonaCreationPopUp
      setShowCreationPopUp={setShowCreationPopUp}></PersonaCreationPopUp>
  ) : null

  const personaPreview = <PersonaPreview
    showPersonaPreview={openPersonaPreview}
    setOpenPersonaPreview={setOpenPersonaPreview}></PersonaPreview>

  console.log("PAGE", openPersonaPreview)

  if (pageLoading) {
    return <ManagerDevPageSkeleton />
  }

  return (
    <div class="w-full h-full flex flex-col px-8 pt-8">
      <div className="mb-8">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>Modules</BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-base font-semibold leading-6 text-gray-900">Persona Library</h1>
          <p class="mt-2 text-sm text-gray-700">View, edit, and create personas</p>
        </div>
        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          {userStore.user.type === 'Manager' || userStore.user.type === 'Admin' ? <button
            type="button"
            class="block rounded-md bg-pareBlue px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleCreateNewPersonaClick}>
            Create Persona
          </button>
            : null}
        </div>
      </div>
      <div class="content-center align-items flex gap-5">
        <input
          type="text"
          placeholder="Search Personas"
          className="mb-8 mt-4 rounded border-grey-200 w-[500px]"
          value={searchBarInput}
          onChange={handleSearchBarChange}></input>
        {/* <button class="w-7 h-7 bg-coverborder-none cursor-pointer hover:opacity-80 mb-7 mt-6" onClick={refresh}>
          <ArrowPathIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
        </button> */}
      </div>
      {activityList.length == 0 ? (
        <div className="w-full h-[40%] flex flex-col items-center justify-center">
          <label class="text-grey-200 mb-2">No Existing Personas</label>
          <label class="text-grey-200">Build, design, and publish a persona for your team when you’re ready</label>
        </div>
      ) : (
          <div className="w-full h-full rounded-md shadow-sm flex flex-row flex-wrap gap-4">
            {searchBarInput.length > 0
              ? activityList
                .filter(x => x.testName.match(new RegExp(`^${searchBarInput}.*`, "i")))
                .map((x, i) => (
                  <div key={i}>
                    <PersonaBox type={"FlexBox"} personaInfo={x} setOpenPersonaPreview={setOpenPersonaPreview}></PersonaBox>
                  </div>
                ))
              : activityList.map((x, i) => (
                <div key={i}>
                  <PersonaBox type={"FlexBox"} personaInfo={x} setOpenPersonaPreview={setOpenPersonaPreview}></PersonaBox>
                </div>
              ))}
          </div>
        )}
      {creationPopUp}
      {personaPreview}
    </div>
  )
}

export default observer(PersonaLibraryPage)

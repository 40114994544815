import React, { useState } from "react"


const SubmitReviewPopUp = ({ header, body1, body2, leftButtonLabel, rightButtonLabel, leftButtonFunction, rightButtonFunction, handleCommentChange, comments, setReviewSuccess, reviewSuccess }) => {


  return (<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

        <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
          <div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">{header}</h3>
              <div class="mt-2 mb-8">
                <p class="text-sm text-gray-500">{body1}</p>
              </div>
            </div>
            <div class="mt-2 mb-8 w-full items-center  flex">
              {reviewSuccess ? (
                <div className="mr-2 w-full flex items-center justify-center">
                  <div className="SelectedMarkAsPassedIcon w-[25%] h-[25px] mr-2"></div>
                  <label className="ml-2 text-green">Mark as Passed</label>
                </div>
              ) : (
                  <div className="ml-2 w-full flex items-center justify-center cursor-pointer" onClick={() => setReviewSuccess(true)}>
                    <div className="NotSelectedMarkAsPassedIcon w-[25%] h-[25px] mr-2"></div>
                    <label className="ml-2 text-gray-500 cursor-pointer">Mark as Passed</label>
                  </div>
                )}
              {reviewSuccess ? (
                <div className="mr-2 w-full flex items-center justify-center cursor-pointer" onClick={() => setReviewSuccess(false)}>
                  <div className="NotSelectedMarkAsNotPassedIcon w-[25%] h-[25px] mr-2"></div>
                  <label className="ml-2 text-gray-500 cursor-pointer" >Mark as Not Passed</label>
                </div>
              ) : (
                  <div className="ml-2 w-full flex items-center justify-center">
                    <div className="SelectedMarkAsNotPassedIcon w-[25%] h-[25px] mr-2"></div>
                    <label className="ml-2 text-red-600">Mark as Not Passed</label>
                  </div>
                )}
            </div>
            <div class="mt-2 mb-8">
              <p class="text-sm text-gray-500">{body2}</p>

            </div>
            <div class="mt-2 mb-8">
              <textarea class="bg-gray-200 rounded h-full w-full" value={comments} onChange={handleCommentChange} />
            </div>
          </div>
          <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button type="button" class="inline-flex w-full justify-center rounded-md bg-pareBlue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pareBlue sm:col-start-2" onClick={rightButtonFunction}>{rightButtonLabel}</button>
            <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0" onClick={leftButtonFunction}>{leftButtonLabel}</button>
          </div>
        </div>
      </div>
    </div>
  </div>)
}

export default SubmitReviewPopUp
import React, { useState, useEffect } from "react";

const CyclingTitle = () => {
  const words = ["AEs", "XDRs", "CSMs", "SDRs", "SEs", "BDRs", "AMs"]
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [animating, setAnimating] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      // Trigger animation
      setAnimating(false);
      setTimeout(() => {
        // After animation, update the word and re-enable animation
        setCurrentWordIndex((prev) => (prev + 1) % words.length);
        setAnimating(true);
      }, 500); // Match transition duration
    }, 2500);

    return () => clearInterval(interval);
  }, []);

  const currentWordClasses = `inline-block transition-all duration-500 ease-in-out text-pareBlue
    ${animating ? "translate-y-0 opacity-100" : "-translate-y-8 opacity-0"}`

  return (
    <h1 className="text-4xl sm:text-6xl font-bold text-center">
      Role playing that your <span className={currentWordClasses}>{words[currentWordIndex]}</span> won't hate
    </h1>
  );
};
export default CyclingTitle;

import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import ActionsMenuDropdown from "../../ActionsMenuDropdown/ActionsMenuDropdown"
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../../../@shadcn_components/ui/sheet"
import { useStores } from "../../../models/root-store"
import "./SingleRecording.css"

const SingleRecording = ({ recording, name, handleMenuPopUp, activityName }) => {
  const { userStore } = useStores()
  const { userId, managerId } = userStore.user || {}
  const [reprocessing, setReprocessing] = useState(false)
  const [showActionsMenu, setShowActionsMenu] = useState(false)
  const [video, setVideo] = useState(recording.video_url)

  const navigate = useNavigate()
  let ready = false
  if (recording.fully_processed === "Y") {
    ready = true
  }


  const toRecordingDetails = () => {
    if (ready === true) {
      if (recording.activity_id) {
        navigate("/moduleAnalysisPage", { state: Object.assign(recording, { testName: activityName }) })
      }
    }
  }
  const epochTime = recording.createdAt
  const date = new Date(epochTime).toDateString()
  const deleteConversationObj = {
    popUpType: "delete",
    conversation_id: recording.conversation_id,
    conversation_title: recording.convo_title,
    conversation_duration: recording.conversation_time_str,
    conversation_date: date,
  }
  const renameConversationObj = {
    popUpType: "rename",
    conversation_id: recording.conversation_id,
    conversation_title: recording.convo_title,
    conversation_duration: recording.conversation_time_str,
    conversation_date: date,
  }
  const deleteRecording = () => {
    handleMenuPopUp(deleteConversationObj)
  }
  const renameRecording = () => {
    handleMenuPopUp(renameConversationObj)
  }
  const handleActionsButtonHover = e => {
    e.stopPropagation()
    setShowActionsMenu(true)
  }
  const handleActionsButtonNoHover = e => {
    e.stopPropagation()
    setShowActionsMenu(false)
  }
  const handleReprocess = async () => {
    //Need to revisit this

  }

  const analysisFailed = recording.failed_reprocessing === "Y"
  const backendReprocess = recording.reprocess === "Y"
  const RecordingStatusLabelStyle = {
    background:
      recording.status === "Pending"
        ? "rgba(255, 199, 0)"
        : recording.status === "Passed"
          ? "rgba(57, 202, 34)"
          : "rgba(255, 94, 94)",
    fontSize: "9px",
    color: "white",
    borderRadius: "5px",
    position: "absolute",
    top: "46%",
    right: "2.5%",
    padding: "5px",
    minWidth: "60px",
    display: "inline-block",
    textAlign: "center",
    fontWeight: 50,
  }

  return ready ? (
    <div
      className={recording.activity_id ? "ActivityRecordingBox" : "recordingBox"}
      onClick={toRecordingDetails}
      onMouseOver={handleActionsButtonHover}
      onMouseLeave={handleActionsButtonNoHover}>
      <div className={recording.activity_id ? "ActivityRecordingVideoContainer" : "RecordingVideoContainer"}>
        <video src={video} height="100%" width="100%" borderRadius="5px"></video>
      </div>
      <div className={recording.activity_id ? "ActivityVideoShader" : "videoShader"}></div>
      {recording.activity_id ? (
        <div style={RecordingStatusLabelStyle}>{recording.status}</div>
      ) : (
          <div className="RecordingBoxCallTypeLabel">{`${recording.callType}`}</div>
        )}
      <label className="recordTitle">{recording.convo_title}</label>
      <div
        className={
          recording.activity_id ? "ActivitySingleRecordingDueDateContainer" : "SingleRecordingDueDateContainer"
        }>
        <div className="SingleRecordingDueDateIcon"></div>
        <label className="recordDate">{date}</label>
      </div>
      <div
        className={
          recording.activity_id ? "ActivitySingleRecordingDurationContainer" : "SingleRecordingDurationContainer"
        }>
        <div className="SingleRecordingDurationIcon"></div>
        <label className="recordingTime">{recording.conversation_time_str}</label>
      </div>
      {recording.activity_id ? (
        <div className="ActivitySingleRecordingCommentsContainer">
          <div className="SingleRecordingCommentIcon"></div>
          {recording.comments ? (
            <div className="ViewCommentsButtonSingleRecording" onClick={e => e.stopPropagation()}>
              <Sheet>
                <SheetTrigger>View Comments</SheetTrigger>
                <SheetContent>
                  <SheetHeader>
                    <SheetTitle>Comments</SheetTitle>
                    <SheetDescription>{recording.comments}</SheetDescription>
                  </SheetHeader>
                </SheetContent>
              </Sheet>
            </div>
          ) : (
              <div className="NOCommentsButtonSingleRecording">No Comments</div>
            )}
        </div>
      ) : null}
      {!recording.activity_id ? (
        <div className="recordingButtonContainer">
          <label className="recordingButton">Review</label>
          <div className="playIcon"></div>
        </div>
      ) : null}
      {showActionsMenu && !recording.activity_id ? (
        <ActionsMenuDropdown
          options={[
            { value: 1, label: "Delete" },
            { value: 2, label: "Rename" },
          ]}
          deleteAction={deleteRecording}
          renameAction={renameRecording}></ActionsMenuDropdown>
      ) : null}
    </div>
  ) : (
      <div className={recording.activity_id ? "ActivityRecordingBoxInactive" : "recordingBoxInactive"}>
        <div className="RecordingVideoContainerInactive"></div>
        <div className="RecordingBoxCallTypeLabel">{`${recording.status}`}</div>
        <label className="recordTitleInactive">{recording.convo_title}</label>
        {backendReprocess || reprocessing ? (
          <label className="analyzingLabel">{"Analyzing..."}</label>
        ) : analysisFailed ? (
          <button
            className="ReprocessButton"
            onClick={null}>
            {"Failed, Click to Reprocess"}
          </button>
        ) : (
              <label className="analyzingLabel">{"Analyzing..."}</label>
            )}
      </div>
    )
}

export default SingleRecording

import React, { useState, useContext } from 'react';
import { Auth, API } from 'aws-amplify';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../models/root-store';
import { NotificationContext } from "../../contexts/notificationContext"


const PersonalInformation = () => {
  const { userStore } = useStores();
  const curr_user_email = userStore.user.userEmail;
  const curr_user_company = userStore.user.company;
  const firstName = userStore.user.firstName;
  const lastName = userStore.user.lastName;
  const profileInitials = userStore.user.profileInitials;
  const profilePicture = userStore.user.profPicSignedUrl;
  const userId = userStore.user.userId
  const [profilePicURL, setProfilePicURL] = useState(null);
  const [profilePicFile, setProfilePicFile] = useState(null);
  const [loadingProPic, setLoadingProPic] = useState(false);
  const { triggerNotification } = useContext(NotificationContext)


  const handleProfilePicChange = async (e) => {
    if (e.target.files[0] && /.*\.(png|jpg|jpeg)$/i.test(e.target.files[0].name)) {
      setProfilePicURL(URL.createObjectURL(e.target.files[0]));
      setProfilePicFile(e.target.files[0]);
      setLoadingProPic(true);

      const extension = e.target.files[0].name.replace(/.*\./, "");
      const signedUrlImgUpload = await API.post("ZenoApp", "/updateProfilePicture", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        body: { extension, user_id: userId },
      });

      await fetch(signedUrlImgUpload, {
        method: "PUT",
        headers: { "Content-Type": `image/${extension}` },
        body: e.target.files[0],
      });

      const imageURL = await API.get("ZenoApp", "/getProfilePicture", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        queryStringParameters: {
          user_id: userId
        }
      });

      userStore.setProfilePicture(imageURL);
      setLoadingProPic(false);
      setProfilePicURL(imageURL);
    } else {
      triggerNotification("error", "Photo format not supported", "Please upload a png or jpg formatted photo.")
    }
  };

  return (
    <div>

      <form className="md:col-span-2">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
          <div className="col-span-full flex items-center gap-x-8">
            {profilePicture ? (
              <img src={profilePicture} alt="" className="h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover" />
            ) : (
                <div className="h-24 w-24 text-base flex items-center justify-center text-white rounded-lg bg-gray-300 object-cover">
                  {profileInitials}
                </div>
              )}
            <div>
              <label htmlFor="profilePhotoInput" className="border-2 text-grey border-gray-300 inline-block px-3 py-1.5 cursor-pointer sm:text-sm sm:leading-6 flex items-center justify-center font-roboto rounded-md hover:bg-gray-300 hover:border-transparent">
                {profilePicFile ? profilePicFile.name : "Upload Profile Picture"}
              </label>
              <input
                type="file"
                id="profilePhotoInput"
                onChange={handleProfilePicChange}
                className="hidden"
              />
              <p className="mt-2 text-xs leading-5 text-gray-400">JPG, GIF or PNG. 1MB max.</p>
            </div>
          </div>

          {/* First Name Field */}
          <div className="sm:col-span-3">
            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-grey">First Name</label>
            <div className="mt-2">
              <input
                type="text"
                name="first-name"
                id="first-name"
                autoComplete="given-name"
                value={firstName}
                readOnly
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-grey shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-pareBlue sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          {/* Last Name Field */}
          <div className="sm:col-span-3">
            <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-grey">Last Name</label>
            <div className="mt-2">
              <input
                type="text"
                name="last-name"
                id="last-name"
                autoComplete="family-name"
                value={lastName}
                readOnly
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-grey shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-pareBlue sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          {/* Email Field */}
          <div className="col-span-full">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-grey">Email Address</label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                value={curr_user_email}
                readOnly
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-grey shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-pareBlue sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          {/* Company Field */}
          <div className="col-span-full">
            <label htmlFor="company" className="block text-sm font-medium leading-6 text-grey">Company</label>
            <div className="mt-2">
              <input
                id="company"
                name="company"
                type="text"
                value={curr_user_company}
                readOnly
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-grey shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-pareBlue sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default observer(PersonalInformation);

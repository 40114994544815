import React, { useState, useContext } from "react"
import { UpdateModule } from "../../api/routes"
import { useStores } from "../../models/root-store"
import { NotificationContext } from "../../contexts/notificationContext"


const moduleInfoMap = {
  "Description": "testDecription",
  "Objective": "testObj",
  "Call Type": "testType",
  "Persona Summary": 'persona_summary',
  "Scenario Background": "contextSummary",
  "Links": "links"
}

const ModuleDetails = ({ moduleCatInfo, setRubricPopUp }) => {
  const { userStore, moduleListStore } = useStores()
  const { userId, type } = userStore.user || {}
  const { triggerNotification } = useContext(NotificationContext)

  const saveModuleChange = async (updatedField, updatedValue, updatedFieldDisplayName) => {
    const res = await UpdateModule(moduleCatInfo.manager_id, moduleCatInfo.activity_id, {
      user_id: userId,
      [updatedField]: updatedValue.trim()
    })
    if (res.kind === 'ok') {
      moduleListStore.updateModuleField(moduleCatInfo.activity_id, updatedField, updatedValue)
      triggerNotification("sucesss", "Module Update successful!", `The ${updatedFieldDisplayName} was successfully updated!`)
    } else {
      triggerNotification("error", 'Module Update Error', res.error.message)

    }
  }

  return (
    <div className="mt-6 border-t border-gray-100">
      <dl className="divide-y divide-gray-100">
        <DetailRow label="Description" value={moduleCatInfo ?.description} saveModuleChange={saveModuleChange} userType={type} />
        <DetailRow label="Objective" value={moduleCatInfo ?.objective} saveModuleChange={saveModuleChange} userType={type} />
        <DetailRow label="Call Type" value={moduleCatInfo ?.testType} saveModuleChange={saveModuleChange} userType={type} />
        <DetailRow label="Persona Summary" value={moduleCatInfo ?.persona_summary} saveModuleChange={saveModuleChange} userType={type} />
        <DetailRow label="Scenario Background" value={moduleCatInfo ?.scenario} saveModuleChange={saveModuleChange} userType={type} />
        <DetailRow
          label="Links"
          value={
            moduleCatInfo ?.links
              ? moduleCatInfo.links.split(",").map((link, index) => (
                <button
                  key={index}
                  onClick={() => {
                    const url = link.startsWith("http://") || link.startsWith("https://") ? link : `https://${link}`
                    window.open(url, "_blank")
                  }}
                  className="rounded-md shadow-md p-2 hover:bg-gray-200">
                  <p className="line-clamp-2 font-semibold text-md text-left">{link}</p>
                </button>
              ))
              : "N/A"
          }
          saveModuleChange={saveModuleChange}
          userType={type}
        />
        {setRubricPopUp && (
          <DetailRow
            label="Rubric"
            value={
              <button onClick={() => setRubricPopUp({ show: true, mode: "view" })} className="rounded-md shadow-md p-2 hover:bg-gray-200">
                <p className="line-clamp-2 font-semibold text-md text-left">Custom Rubric</p>
              </button>
            }
            setRubricPopUp={setRubricPopUp}
            userType={type}
          />
        )}
      </dl>
    </div>
  )
}

const DetailRow = ({ label, value, saveModuleChange, setRubricPopUp, userType }) => {
  const [editMode, setEditMode] = useState(false)
  const moduleField = moduleInfoMap[label]
  const [currentValue, setCurrentValue] = useState(value)

  const handleInputChange = e => {
    setCurrentValue(e.target.value)
  }
  function autoResize(event) {
    const textarea = event.target;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  const handleEditCancel = () => {
    setEditMode(false)
    setCurrentValue(value)
  }

  const handleEditClick = () => {
    if (label === "Rubric") {
      setRubricPopUp({ show: true, mode: "edit" })
    } else {
      setEditMode(true)
    }
  }

  const handleSaveClick = async () => {
    saveModuleChange(moduleField, currentValue, label)
    setEditMode(false)

  }

  if (userType === "AE" || userType === "SDR") {
    return (
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm/6 font-medium text-gray-900">{label}</dt>
        <dd className="mt-1 flex text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0 whitespace-pre-line">{value || "N/A"}</dd>
      </div>)
  }

  return (
    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt className="text-sm/6 font-medium text-gray-900">{label}</dt>
      <dd className="mt-1 flex text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
        {editMode ? <>
          <span className="grow">
            <textarea value={currentValue} className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-pareBlue sm:max-w-md sm:text-sm sm:leading-6 resize"
              onChange={handleInputChange}
              onSelect={autoResize}></textarea>
          </span>
          <span className="ml-4 shrink-0">
            <button className="rounded-md bg-white font-medium text-pareBlue hover:text-pareBlue mr-4"
              onClick={handleSaveClick}>
              Save
            </button>
            <button className="rounded-md bg-white font-medium text-red-600 hover:text-red-500"
              onClick={handleEditCancel}>
              Cancel
            </button>
          </span>
        </>
          : <>
            <span className="grow whitespace-pre-line">{currentValue}</span>
            <span className="ml-4 shrink-0">
              <button type="button" className="rounded-md bg-white font-medium text-pareBlue hover:text-pareBlue"
                onClick={handleEditClick}>
                Edit
          </button>
            </span>
          </>}
      </dd>
    </div>
  )
}

export default ModuleDetails

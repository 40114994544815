import React from "react"



const SingleOptionPopUp = ({ header, body, buttonLabel, buttonFunction }) => {


  return (<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

        <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-6/12 ">
          <div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 class="text-base font-semibold leading-6 text-gray-900 mb-4" id="modal-title">{header}</h3>
              <div class="p-2 mt-2 text-left overflow-auto">
                <p class="text-sm text-gray-500 whitespace-pre-line ">{body}</p>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6">
            <button type="button" class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={buttonFunction}>{buttonLabel}</button>
          </div>
        </div>
      </div>
    </div>
  </div>)

}

export default SingleOptionPopUp;
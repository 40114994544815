import { Menu, MenuButton, MenuItems } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/20/solid"

const GenericDropdown = ({ options, buttonLabel = "Options" }) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
        {buttonLabel}
        <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
      </MenuButton>

      <MenuItems className="absolute right-0 z-10 mt-2 w-44 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
        <div className="py-1">
          {options.map((option, index) => (
            <div
              key={index}
              onClick={option.onClick}
              className={`cursor-pointer block px-4 py-2 text-sm ${
                option.isDelete
                  ? "text-red-600 hover:bg-red-100"
                  : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              }`}>
              {option.label}
            </div>
          ))}
        </div>
      </MenuItems>
    </Menu>
  )
}

export default GenericDropdown

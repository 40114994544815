import React from "react"
import { useNavigate, useLocation } from "react-router-dom"
import SideBarItem from "./SideBarItem"
import { useStores } from "../../models/root-store"
import { observer } from "mobx-react-lite"
import SideBarProfileBanner from "./SideBarProfileBanner"
import "./SideBar.css"

const pareLogo = require("./SideBarIcons/PareLogo_new.png")

function SideBar() {
  const { userStore } = useStores()
  const navigate = useNavigate()
  const { pathname, state } = useLocation()

  if (!userStore.user) return null

  const signOutFunction = async () => {
    await userStore.logout()
    navigate("/")
  }

  // Contains sidebar info (current page, whether manager), for all
  // pages where we should show the sidebar
  const ROUTE_TO_SIDEBAR_INFO = {
    "/homepage": {
      isManager: userStore.user.type === "Manager" || userStore.user.type === "Admin",
      sideBarPageLabel: locationState => "Home",
    },
    "/context": {
      isManager: false,
      sideBarPageLabel: locationState => {
        if (locationState != null && locationState.source === "homepage") {
          return "Home"
        } else if (locationState != null && locationState.source === "accountData") {
          return "Recordings"
        }
      },
    },
    "/settings": {
      isManager: userStore.user.type === "Manager" || userStore.user.type === "Admin",
      sideBarPageLabel: locationState => "Settings",
    },
    "/managerDashboard": {
      isManager: true,
      sideBarPageLabel: locationState => "Home",
    },
    "/managerTeam": {
      isManager: true,
      sideBarPageLabel: locationState => "Team",
    },
    "/managerDev": {
      isManager: true,
      sideBarPageLabel: locationState => "Development",
    },
    "/activityTeamView": {
      isManager: true,
      sideBarPageLabel: locationState => "Development",
    },
    "/activityCreationPage": {
      isManager: true,
      sideBarPageLabel: locationState => "Development",
    },
    "/moduleAnalysisPage": {
      isManager: userStore.user.type === "Manager" || userStore.user.type === "Admin",
      sideBarPageLabel: locationState => "NOTHING?",
    },
    "/assignmentsPage": {
      isManager: userStore.user.type === "Manager" || userStore.user.type === "Admin",
      sideBarPageLabel: locationState => "Development",
    },
    "/assignmentLandingPage": {
      isManager: userStore.user.type === "Manager" || userStore.user.type === "Admin",
      sideBarPageLabel: locationState => "Development",
    },
    "/chat": {
      isManager: userStore.user.type === "Manager" || userStore.user.type === "Admin",
      sideBarPageLabel: locationState => "NOTHING?",
    },
    // "/chatRoomPage": {
    //   isManager: userStore.user.type === "Manager" || userStore.user.type === "Admin",
    //   sideBarPageLabel: locationState => "NOTHING?",
    // },
    "/PresentationPage": {
      isManager: userStore.user.type === "Manager" || userStore.user.type === "Admin",
      sideBarPageLabel: locationState => "NOTHING?",
    },
    "/learningCenterPage": {
      isManager: userStore.user.type === "Manager" || userStore.user.type === "Admin",
      sideBarPageLabel: locationState =>
        userStore.user.type === "Manager" || userStore.user.type === "Admin"
          ? "learningCenterPage"
          : "learningCenterPage",
    },
    "/personaLibrary": {
      isManager: userStore.user.type === "Manager" || userStore.user.type === "Admin",
      sideBarPageLabel: locationState => "personaLibrary",
    },

  }

  let currPageInfo = ROUTE_TO_SIDEBAR_INFO[pathname]

  if (currPageInfo == null) {
    return <div></div>
  }
  let currPageName = currPageInfo.sideBarPageLabel(state)
  let isManager = currPageInfo.isManager

  // Doing this here to avoid nested inline decisions
  const homeSelected = currPageName === "Home"
  const teamSelected = currPageName === "Team"
  const developmentSelected = currPageName === "Development"
  const settingsSelected = currPageName === "Settings"
  const learningCenterSelected = currPageName === "learningCenterPage"
  const personaLibrarySelected = currPageName === "personaLibrary"

  return (
    <div className="bg-white border border-gray-300 border-t-transparent h-full flex-none flex flex-col items-center md:items-start w-16 md:w-48">
      <img src={pareLogo} className="w-3/4 mb-4 md:w-10 rounded-xl mt-4 ml-0 md:ml-4 md:mb-4" alt="logo" />
      <div className="w-full">
        <div className="hidden md:block ml-4 w-full font-roboto font-medium text-black text-opacity-50 text-[12px]">OVERVIEW</div>
        <div className="SideBannerItems ml-4">
          <SideBarItem
            label="Dashboard"
            icon={"home"}
            onClick={homeSelected ? null : () => navigate(isManager ? "/homepage" : "/homepage")}
            isShaded={homeSelected}
          />
          {isManager ? (
            <SideBarItem
              label={"Team"}
              icon={"team"}
              onClick={teamSelected ? null : () => navigate("/managerTeam")}
              isShaded={teamSelected}
            />
          ) : null}
        </div>
        <div className="hidden md:block ml-4 w-full font-roboto font-medium text-black text-opacity-50 text-[14px]">ENGAGE</div>
        <div className="SideBannerItems ml-4">
          <SideBarItem
            label={"Modules"}
            icon={"modules"}
            onClick={developmentSelected ? null : () => navigate("/assignmentsPage")}
            isShaded={developmentSelected}
          />
        </div>
        <div className="SideBannerItems ml-4">
          {isManager ? (
            <SideBarItem
              label={"Coaching"}
              icon={"coaching"}
              onClick={learningCenterSelected ? null : () => navigate("/learningCenterPage")}
              isShaded={learningCenterSelected}
            />
          ) : (
              <SideBarItem
                label={"Learning Center"}
                icon={"learningCenter"}
                onClick={learningCenterSelected ? null : () => navigate("/learningCenterPage")}
                isShaded={learningCenterSelected}
              />
            )}
        </div>
        {/* <div className="SideBannerItems ml-4">
          {isManager ? <SideBarItem
            label={"Persona Library"}
            icon={"team"}
            onClick={personaLibrarySelected ? null : () => navigate("/personaLibrary")}
            isShaded={personaLibrarySelected} />
            : <SideBarItem
              label={"Playground"}
              icon={"playground"}
              onClick={personaLibrarySelected ? null : () => navigate("/personaLibrary")}
              isShaded={personaLibrarySelected} />
          }

        </div> */}
        <div className="SideBannerItems ml-4">
          <SideBarItem
            label={"Settings"}
            icon={"settings"}
            onClick={settingsSelected ? null : () => navigate("/settings")}
            isShaded={settingsSelected}
          />
        </div>
      </div>
      <SideBarProfileBanner
        name={userStore.user.name}
        email={userStore.user.userEmail}
        profilePicture={userStore.user.profilePicture}
        navigateToSettings={() => navigate('/settings')}
        signOutFunction={signOutFunction}></SideBarProfileBanner>
    </div>
  )
}

export default observer(SideBar)

import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import Messages from "../../components/Messages/Messages"
import { Auth, API } from "aws-amplify"
import { toJS } from "mobx";
import { grid } from "ldrs"
import "./ModuleAnalysisPage.css"
import "video-react/dist/video-react.css"
import { Tabs, TabsList, TabsTrigger } from "../../@shadcn_components/ui/tabs"
import DynamicText from "../../components/TextComponents/DynamicText"
import SubmitReviewPopUp from "../../components/PopUps/SubmitReviewPopUp"
import LoadingPopUp from "../../components/PopUps/LoadingPopUp"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../@shadcn_components/ui/breadcrumb"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"

grid.register()

const ModuleAnalysisPage = () => {
  const { userStore } = useStores()
  const location = useLocation()
  const transcript = location.state.transcript
  const totalDuration = location.state.conversation_time
  const conversationEnd = Date.parse(transcript[transcript.length - 1].end)
  const conversationStart = Date.parse(transcript[0].start)
  const totalDurationString = location.state.conversation_time_str
  const videoURL = location.state.video_url
  const aiReport = location.state.grading_report.replace(/\|\|.*\|\|\n/gm, "")
  const [viewType, setViewType] = useState("Transcript")
  const [searchBarInput, setSearchBarInput] = useState("")
  const [talkTrackPos, setTalkTrackPos] = useState(0)
  const teamMemberName = location.state.name
  const rubricScore = location.state.rubric_score.replace(/Overall Score:/g, "")
  const [showSubmitReviewPopUp, setShowSubmitReviewPopUp] = useState(false)
  const [popUpLoading, setPopUpLoading] = useState(false)
  const [comments, setComments] = useState("")
  const date = new Date(location.state.createdAt).toDateString()
  const [reviewSuccess, setReviewSuccess] = useState(true)

  const handleCommentChange = e => {
    setComments(e.target.value)
  }

  const handleSearchBarChange = e => {
    e.preventDefault()
    setSearchBarInput(e.target.value)
  }
  const markAsPassed = async () => {
    setPopUpLoading(true)
    await API.post("ZenoApp", "/markAsPassed", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      body: {
        manager_id: location.state.manager_id,
        activity_id: location.state.activity_id,
        user_id: userStore.user.userId,
        attempt_user_id: location.state.user_id,
        conversation_id: location.state.conversation_id,
        passed: reviewSuccess,
        comments: comments,
      },
    })
    setPopUpLoading(false)
    setShowSubmitReviewPopUp(false)
  }

  const handleTimeUpdate = event => {
    setTalkTrackPos(event.target.currentTime)
  }

  const exitPopUp = () => {
    setShowSubmitReviewPopUp(false)
  }

  const submitReviewPopUp = showSubmitReviewPopUp ? (
    <div className={`frostedBackground is-visible`}>
      {popUpLoading ? (
        <LoadingPopUp header="Submitting Feedback..."></LoadingPopUp>
      ) : (
          <SubmitReviewPopUp header="Submit Review" body1="Select whether the rep passed this module or try again" body2="Leave some feedback for your rep on what influenced your decision" rightButtonFunction={markAsPassed} rightButtonLabel="Submit" leftButtonFunction={exitPopUp} leftButtonLabel="Cancel" comments={comments} handleCommentChange={handleCommentChange} setReviewSuccess={setReviewSuccess} reviewSuccess={reviewSuccess}></SubmitReviewPopUp>
        )}
    </div>
  ) : null

  const handleSubmitReviewButtonClick = () => {
    setShowSubmitReviewPopUp(true)
  }

  const isManager = userStore.user.type === "Manager" || userStore.user.type === "Admin"
  const pageName = isManager ? `${teamMemberName}'s Attempt` : "My Attempt"
  const breadCrumbView = (
    <div className="flex flex-col items-start">
      <div className="mb-8">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href={isManager ? "/managerDev" : "/assignmentsPage"}>Modules</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink>{`${location.state.testName}`}</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{pageName}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div className="w-full flex flex-row justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold">{location.state.testName}</h1>
        {isManager ? (
          <button
            type="button"
            onClick={handleSubmitReviewButtonClick}
            className="rounded-md bg-pareBlue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Submit Review
        </button>
        ) : null}
      </div>
    </div>
  )

  const cardsView =
    <div className="cardsContainer mb-4">
      {isManager ? (
        <div className="cardContainer">
          <label>{teamMemberName}</label>
        </div>
      ) : null}
      <div className="cardContainer">
        <div className="blackCalendarIcon"></div>
        <label>{date}</label>
      </div>
      <div className="cardContainer">
        <div className="blackClockIcon"></div>
        <label>{totalDurationString}</label>
      </div>
    </div>

  const tabNames = [
    { name: "Transcript", key: "Transcript" },
    { name: "Scoring", key: "Scoring" },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }

  const tabs = (
    <header className="border-b border-gray-200 px-6 mb-3">
      <div className="mt-3 sm:mt-4">
        {/* Mobile dropdown */}
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
            </label>
          <select
            id="current-tab"
            name="current-tab"
            defaultValue={tabNames.find(tab => tab.key === viewType).name}
            onChange={e => setViewType(tabNames.find(tab => tab.name === e.target.value).key)}
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
            {tabNames.map(tab => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>

        {/* Desktop tabs */}
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">
            {tabNames.map(item => (
              <button
                key={item.name}
                onClick={() => setViewType(item.key)}
                aria-current={viewType === item.key ? "page" : undefined}
                className={classNames(
                  viewType === item.key
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium",
                )}>
                {item.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </header>
  )

  const pendingView = (
    <div className="flex-1 flex flex-row content-center justify-center">
      <l-grid size="120" speed="1.4" color="#3045FF" className="mt-50" />
    </div>
  )

  const videoView = (
    <div className="flex-1 aspect-[16/9] w-1/2 ">
      <video src={videoURL} controls height="100%" width="100%" preload="auto" onTimeUpdate={handleTimeUpdate}></video>
    </div>
  )

  const messagesToDisplay = searchBarInput.length
    ? transcript.filter(x => x.text.match(new RegExp(`^.*${searchBarInput}.*`, "i")))
    : transcript

  const transcriptView = (
    <div className="flex flex-1  flex-col 2xl:flex-row px-4 overflow-auto">
      <div className="flex flex-col px-2">
        <input
          type="text"
          placeholder="Search"
          className="w-full rounded-sm border-2 border-gray-400"
          value={searchBarInput}
          onChange={handleSearchBarChange}></input>
      </div>
      <div className="flex-1 overflow-auto">
        <Messages messages={messagesToDisplay} name={""} />
      </div>
    </div>
  )

  const heatmapView = (
    <div className="w-1/2">
      <div className="px-2">
        <p className="text-xl font-semibold">{`${teamMemberName}`}</p>
        <div className="relative h-[2px] bg-gray-500 my-4">
          {transcript
            .filter(x => x.user === "user")
            .map(x => (
              <div
                key={`user-bar-${x.start}`}
                style={{
                  width: `${((Date.parse(x.end) - Date.parse(x.start)) / 1000 / totalDuration) * 100}%`,
                  height: "10px",
                  background: "#3045ff",
                  position: "absolute",
                  top: "-4px",
                  left: `${(((Date.parse(x.start) - conversationStart) / 1000) / totalDuration) * 100}%`,
                  borderRadius: "5px",
                }}></div>
            ))}
          <div
            style={{
              width: "3px",
              height: "20px",
              background: "black",
              position: "absolute",
              top: "-10px",
              left: `${(talkTrackPos / totalDuration) * 100}%`,
            }}></div>
        </div>
      </div>
      <div className="px-2">
        <p className="text-xl font-semibold">{`Prospect`}</p>
        <div className="relative h-[2px] bg-gray-500 my-4">
          {transcript
            .filter(x => x.user === "bot")
            .map(x => (
              <div
                key={`bot-bar-${x.start}`}
                style={{
                  width: `${(((Date.parse(x.end) - Date.parse(x.start)) / 1000) / totalDuration) * 100}%`,
                  height: "10px",
                  background: "grey",
                  position: "absolute",
                  top: "-4px",
                  left: `${(((Date.parse(x.start) - conversationStart) / 1000) / totalDuration) * 100}%`,
                  borderRadius: "5px",
                }}></div>
            ))}
          <div
            style={{
              width: "3px",
              height: "20px",
              background: "black",
              position: "absolute",
              top: "-10px",
              left: `${(talkTrackPos / totalDuration) * 100}%`,
            }}></div>
        </div>
      </div>
    </div>
  )

  // "Overview" tab
  const overviewTab = (
    <div className="flex-1 flex flex-col">
      <div className="flex flex-row mb-4 h-full">
        {videoView}
        {transcriptView}
      </div>
      {heatmapView}

    </div>
  )

  // "Scoring" tab
  const scoringTab = (
    <div className="flex flex-1  flex-col 2xl:flex-row px-4 overflow-auto">
      <div className="flex flex-col items-center px-10 pt-12 ">
        <p className="text-3xl font-bold mb-3">{rubricScore}</p>
        <div className="text-md text-gray-300">Score</div>
      </div>

      <div className="whitespace-pre-line text-lg 2xl: text-xl text-slate-400">
        <DynamicText content={aiReport}></DynamicText>
      </div>
    </div>
  )

  return (
    <div className="w-full h-full flex flex-col px-8 2xl:px-20 pt-8">
      {breadCrumbView}
      {cardsView}
      <div class="relative h-screen border-t border-gray-200 border">
        <div class="absolute top-0 left-0 w-full h-1/2 flex-col flex">
          {videoView}
          {heatmapView}
        </div>

        <div class="absolute top-0 right-0 h-full w-1/2 border border-gray-200 flex flex-col">
          {tabs}
          {viewType === 'Transcript' ? transcriptView : scoringTab}
        </div>
      </div>
      {submitReviewPopUp}
    </div>
  )
}

export default observer(ModuleAnalysisPage)

import React, { useState } from "react"
import Avatar from "../Global/avatar"

const ActivityTeamViewTableEntry = ({ teamMember, getAttempts, overdueChecker, handleDueDateChange, sendReminder, testName, dueDate }) => {

  const [currentDueDate, setCurrentDueDate] = useState(teamMember.dueDate)

  return (
    <tr key={teamMember.user_id} class="group hover:bg-gray-100 cursor-pointer" key={teamMember.user_id} onClick={() => getAttempts(teamMember.user_id, teamMember.name, teamMember.conversation_id)}>
      <td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0" >
        <div class="flex items-center">
          <div class="h-11 w-11 flex-shrink-0">
            <Avatar name={teamMember.name} profilePicture={teamMember.profilePicture} size="lg" />
          </div>
          <div class="ml-4">
            <div class="font-medium text-gray-900">{teamMember.name}</div>
            <div class="mt-1 text-gray-500">{teamMember.email}</div>
          </div>
        </div>
      </td>
      <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        {teamMember.status === "Incomplete" && overdueChecker(currentDueDate)
          ? <span class="inline-flex items-center rounded-md bg-red_light px-2 py-1 text-xs font-medium text-red ">Overdue</span>
          : teamMember.status === "Incomplete" && !overdueChecker(currentDueDate)
            ? <span class="inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-500 ">Not Started</span>
            : teamMember.status === "Pending"
              ? <span class="inline-flex items-center rounded-md bg-yellow_light px-2 py-1 text-xs font-medium text-yellow ">Pending</span>
              : teamMember.status === "Not Passed" || teamMember.status === "In Progress"
                ? <span class="inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-pareBlue ">In Progress</span>
                : <span class="inline-flex items-center rounded-md bg-green_light px-2 py-1 text-xs font-medium text-green ">Passed</span>}
      </td>
      <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        <div class="mt-1 text-gray-500">
          <input
            type="date"
            className="border-none outline-none group-hover:bg-gray-100 cursor-pointer"
            value={currentDueDate}
            onChange={(e) => {
              handleDueDateChange(teamMember.user_id, e.target.value)
              setCurrentDueDate(e.target.value)
            }}
            onClick={(e) => { e.stopPropagation() }}
          ></input>
        </div>
      </td>

      <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{teamMember.conversation_id.length}</td>
      <td class="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
        <button class="text-pareBlue hover:text-indigo-900"
          onClick={e => sendReminder(
            e,
            teamMember.name,
            teamMember.email,
            testName,
            dueDate,
          )
          }>Remind</button>
      </td>
    </tr>
  )
}

export default ActivityTeamViewTableEntry
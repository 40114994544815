import React, { Fragment, useState } from "react"
import Avatar from "../Global/avatar"
import GenericDropdown from "../Global/genericDropdown"
import ManagerSelectPopup from "./managerSelectPopup"

const GroupedTeamTable = ({
  teamMemberList,
  inviteeList,
  openDeletePopUp,
  deleteInvitee,
  updateManagerInfo,
  resendInvite,
  currentUserName,
}) => {
  const [showManagerSelectPopup, setShowManagerSelectPopup] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [isActiveUser, setIsActiveUser] = useState(false)

  const managers = teamMemberList.filter(member => member.type === "Manager")
  const managerNames = managers.map(manager => manager.name)

  const getUsersForManager = manager => ({
    teamMembers: teamMemberList.filter(member => member.managerEmail === manager.userEmail),
    invitees: inviteeList.filter(invitee => invitee.managerId === manager.userId),
  })

  const unassignedMembers = teamMemberList.filter(member => member.managerEmail === "UNDEFINED")
  const unassignedInvitees = inviteeList.filter(invitee => invitee.managerId === "UNDEFINED")
  const orgAdmins = teamMemberList.filter(member => member.type === "Admin")

  const handleUpdateManagerClick = (user, isFromTeamMemberList) => {
    setSelectedUser(
      isFromTeamMemberList
        ? {
            userId: user.userId,
            userEmail: user.userEmail,
            managerId: user.managerId,
            managerName: user.managerName,
            name: user.name,
          }
        : {
            userEmail: user.userEmail,
            managerId: user.managerId,
            managerName: user.managerId != "UNDEFINED" ? user.invitedBy : "UNDEFINED",
          },
    )
    setIsActiveUser(isFromTeamMemberList)
    setShowManagerSelectPopup(true)
  }

  const handleConfirmManagerUpdate = selectedManager => {
    if (selectedUser && selectedUser.userEmail) {
      const manager = managers.find(mgr => mgr.name === selectedManager)
      if (manager) {
        updateManagerInfo(selectedUser.userEmail, manager.userId, manager.userEmail, manager.name, isActiveUser)
      }
      setShowManagerSelectPopup(false)
      setSelectedUser(null)
    }
  }

  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                  Name
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Title
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Status
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Last Login
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  <span className="sr-only">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {/* Unassigned Users Section */}
              <Fragment>
                <tr className="border-t border-gray-200">
                  <th
                    colSpan={5}
                    className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                    Unassigned Users
                  </th>
                </tr>
                {unassignedMembers.length || unassignedInvitees.length ? (
                  <>
                    {unassignedMembers.map((member, idx) => (
                      <UserRow
                        key={member.userEmail}
                        user={member}
                        currentUserName={currentUserName}
                        openDeletePopUp={openDeletePopUp}
                        updateManagerInfo={user => handleUpdateManagerClick(user, true)}
                      />
                    ))}
                    {unassignedInvitees.map((invitee, idx) => (
                      <InviteRow
                        key={invitee.userEmail}
                        invitee={invitee}
                        resendInvite={resendInvite}
                        updateManagerInfo={invitee => handleUpdateManagerClick(invitee, false)}
                        deleteInvitee={deleteInvitee}
                      />
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={5} className="py-4 px-4 text-sm text-gray-500">
                      No unassigned users
                    </td>
                  </tr>
                )}
              </Fragment>

              {/* Org Admins Section */}
              <Fragment>
                <tr className="border-t border-gray-200">
                  <th
                    colSpan={5}
                    className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                    Org Admins
                  </th>
                </tr>
                {orgAdmins.map((admin, idx) => (
                  <UserRow
                    key={admin.userEmail}
                    user={admin}
                    currentUserName={currentUserName}
                    openDeletePopUp={openDeletePopUp}
                  />
                ))}
              </Fragment>

              {/* Manager Teams Section */}
              {managers.map(manager => {
                const { teamMembers, invitees } = getUsersForManager(manager)
                return (
                  <Fragment key={manager.userId}>
                    <tr className="border-t border-gray-200">
                      <th
                        colSpan={5}
                        className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                        {manager.name}'s Team
                      </th>
                    </tr>
                    {teamMembers.map((member, idx) => (
                      <UserRow
                        key={member.userEmail}
                        user={member}
                        currentUserName={currentUserName}
                        openDeletePopUp={openDeletePopUp}
                        updateManagerInfo={user => handleUpdateManagerClick(user, true)}
                      />
                    ))}
                    {invitees.map((invitee, idx) => (
                      <InviteRow
                        key={invitee.userEmail}
                        invitee={invitee}
                        resendInvite={resendInvite}
                        updateManagerInfo={invitee => handleUpdateManagerClick(invitee, false)}
                        deleteInvitee={deleteInvitee}
                      />
                    ))}
                  </Fragment>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      {showManagerSelectPopup && selectedUser && (
        <ManagerSelectPopup
          currentUserName={selectedUser.name || selectedUser.userEmail}
          currentManagerName={selectedUser.managerName}
          managerNames={managerNames.filter(name => name !== selectedUser.managerName)}
          onConfirm={handleConfirmManagerUpdate}
          onClose={() => setShowManagerSelectPopup(false)}
        />
      )}
    </div>
  )
}

// Separate component for a user row
const UserRow = ({ user, currentUserName, openDeletePopUp, updateManagerInfo }) => (
  <tr>
    <td className="whitespace-nowrap flex items-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
      <Avatar name={user.name} profilePicture={user.profilePicture} size="lg" />
      <div className="ml-4">
        <div>{user.name}</div>
        <div className="mt-1 text-gray-500">{user.email}</div>
      </div>
    </td>
    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.type}</td>
    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
      <span className="inline-flex items-center rounded-md bg-green_light px-2 py-1 text-xs font-medium text-green">
        Active
      </span>
    </td>
    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
      {new Date(user.lastLoginDatetime).toDateString()}
    </td>
    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
      {user.name !== currentUserName ? (
        <GenericDropdown
          options={[
            ...(user.type !== "Admin"
              ? [
                  {
                    label: user.managerId === "UNDEFINED" ? "Assign Manager" : "Update Manager",
                    onClick: () => updateManagerInfo(user),
                  },
                ]
              : []),
            { label: "Delete User", onClick: () => openDeletePopUp(user), isDelete: true },
          ]}
        />
      ) : (
        <span className="text-gray-400">You</span>
      )}
    </td>
  </tr>
)

// Separate component for an invitee row
const InviteRow = ({ invitee, resendInvite, updateManagerInfo, deleteInvitee }) => (
  <tr>
    <td className="whitespace-nowrap flex items-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
      <Avatar name={invitee.userEmail} size="lg" />
      <div className="ml-4">
        <div className="mt-1 text-gray-500">{invitee.userEmail}</div>
      </div>
    </td>
    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{invitee.type}</td>
    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
      <span className="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-500">
        Invited
      </span>
    </td>
    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">N/A</td>
    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
      <GenericDropdown
        options={[
          { label: "Resend Invite", onClick: () => resendInvite(invitee.userEmail) },
          ...(invitee.type !== "Manager"
            ? [
                {
                  label: invitee.managerId === "UNDEFINED" ? "Assign Manager" : "Change Manager",
                  onClick: () => updateManagerInfo(invitee),
                },
              ]
            : []),
          { label: "Revoke Invite", onClick: () => deleteInvitee(invitee.userEmail), isDelete: true },
        ]}
      />
    </td>
  </tr>
)

export default GroupedTeamTable

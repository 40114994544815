import { types, Instance, flow, SnapshotIn } from "mobx-state-tree"
import { getSalesTeam, getTeamProfilePictures } from "../../api/routes"

// Sales Rep Model
const SalesRepModel = types
  .model("SalesRep", {
    lastLoginDatetime: types.number,
    userEmail: types.string,
    userId: types.string,
    name: types.string,
    type: types.string,
    profilePicture: types.maybeNull(types.string),
    managerEmail: types.string,
    managerId: types.string,
    managerName: types.string,
  })
  .views(self => ({
    get firstName() {
      return self.name ?.split(" ") ?.[0] || "Rep"
    },
    get lastName() {
      return self.name ?.split(" ") ?.slice(-1)[0] || "Rep"
    },
  }))
  .views(self => ({
    get profileInitials() {
      return `${(self.firstName || "").charAt(0)}${(self.lastName || "").charAt(0)}`
    },
    get profPicSignedUrl() {
      return self.profilePicture || "PP_NOT_DEFINED"
    },
  }))

const InvitedRepModel = types.model("InvitedRep", {
  userEmail: types.string,
  type: types.string,
  managerId: types.string,
  invitedBy: types.string,
})

const TeamInfoFieldsModel = types.model("TeamInfoFields", {
  managerId: types.maybe(types.string),
  team: types.array(SalesRepModel),
  inviteeList: types.array(InvitedRepModel),
})

export type TeamInfoFieldsSnapshotIn = SnapshotIn<typeof TeamInfoFieldsModel>

// Team Store Model
export const TeamStoreModel = types
  .model("TeamStore", {
    teamInfo: types.maybeNull(TeamInfoFieldsModel),
  })
  .views(self => ({
    // Get the number of team members
    get teamSize() {
      return self.teamInfo.team.length
    },
  }))
  .actions(self => ({
    // Fetch the team data from an API
    getTeam: flow(function* getTeam(managerId, userType, company, user_id) {
      try {
        const teamRes = yield getSalesTeam(managerId, userType, company, user_id);
        const userIds = teamRes.data.team.map(x => x.userId)
        const proPicRes = yield getTeamProfilePictures(userIds, user_id)
        const teamWithProPics = teamRes.data.team.map(rep => {
          rep["profilePicture"] = proPicRes.data[rep.userId]
          return rep
        })

        if (teamRes.kind === "ok") {
          self.teamInfo = TeamInfoFieldsModel.create({
            managerId: teamRes.data.managerId,
            team: teamWithProPics.map(rep => SalesRepModel.create(rep)),
            inviteeList: teamRes.data.inviteeList.map(invitee =>
              InvitedRepModel.create({
                userEmail: invitee.email,
                type: invitee.type,
                managerId: invitee.managerId,
                invitedBy: invitee.invitedBy,
              }),
            ),
          })
          return true
        }
      } catch (e) {
        console.error("Error fetching team data:", e.message)
      }
      return false
    }),

    // Add a new sales rep to the team
    addSalesRep(rep) {
      const newRep = SalesRepModel.create(rep)
      self.teamInfo.team.push(newRep)
    },

    updateSalesRepManagerInfo(userEmail, managerId, managerEmail, managerName) {
      const index = self.teamInfo.team.findIndex(currentRep => currentRep.userEmail === userEmail)
      self.teamInfo.team[index].managerId = managerId
      self.teamInfo.team[index].managerEmail = managerEmail
      self.teamInfo.team[index].managerName = managerName
    },

    // Remove a sales rep from the team by id
    removeSalesRep(userId) {
      const index = self.teamInfo.team.findIndex(rep => rep.userId === userId)
      if (index > -1) {
        self.teamInfo.team.splice(index, 1) // This mutates the existing array
      }
    },
  }))
  .actions(self => ({
    // Add a new sales rep to the team
    addInvitedRep(inviteeArray, type, managerId, invitedBy) {
      inviteeArray.forEach(invitee => {
        self.teamInfo.inviteeList.push(InvitedRepModel.create({ userEmail: invitee, type, managerId, invitedBy }))
      })
    },

    updateInvitedRepManagerInfo(userEmail, managerId, invitedBy) {
      const index = self.teamInfo.inviteeList.findIndex(rep => rep.userEmail === userEmail)
      self.teamInfo.inviteeList[index].managerId = managerId
      self.teamInfo.inviteeList[index].invitedBy = invitedBy
    },

    // Remove a sales rep from the team by id
    removeInvitedRep(repEmail) {
      const index = self.teamInfo.inviteeList.findIndex(rep => rep.userEmail === repEmail)
      if (index > -1) {
        self.teamInfo.inviteeList.splice(index, 1) // This mutates the existing array
      }
    },
  }))

export type TeamStore = Instance<typeof TeamStoreModel>

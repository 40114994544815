import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import ActionsMenuDropdown from "../ActionsMenuDropdown/ActionsMenuDropdown"
import "./ActivityBox.css"
import { FlagIcon, UserCircleIcon, PhoneIcon } from "@heroicons/react/20/solid"
import { NotificationContext } from "../../contexts/notificationContext"


const ActivityBox = ({ activityInfo, handleMenuPopUp }) => {
  let navigate = useNavigate()
  const { triggerNotification } = useContext(NotificationContext)


  const toActivityTeamView = () => {
    if (activityInfo.doneCreation === "Y") {
      navigate("/activityTeamView", { state: activityInfo })
    } else {
      triggerNotification("error", "Module is being created!", "Please wait while the module is being generated")
    }
  }

  const deleteConversationObj = {
    popUpType: "delete",
    activity_id: activityInfo.activity_id,
    manager_id: activityInfo.manager_id,
    testName: activityInfo.testName,
  }
  const renameConversationObj = {
    popUpType: "rename",
    activity_id: activityInfo.activity_id,
    manager_id: activityInfo.manager_id,
    testName: activityInfo.testName,
  }
  const deleteActivity = () => {
    handleMenuPopUp(deleteConversationObj)
  }
  const renameActivity = () => {
    handleMenuPopUp(renameConversationObj)
  }
  const overdueChecker = dueDate => {
    const stringToDate = new Date(dueDate)
    const epochDueDate = stringToDate.getTime()
    const today = new Date().toDateString()
    const epochToday = new Date(today).getTime()
    return epochToday > epochDueDate
  }
  const participantsObj = activityInfo.testParticipants ? JSON.parse(activityInfo.testParticipants) : {}

  const participantKeys = Object.keys(participantsObj)
  const completionDist = participantKeys.reduce(
    (acc, x) => {
      if (participantsObj[x].status === "Incomplete") {
        if (overdueChecker(participantsObj[x].dueDate)) {
          acc["Overdue"] += 1
        } else {
          acc["Not Started"] += 1
        }
      } else if (participantsObj[x].status === "Passed") {
        acc["Passed"] += 1
      } else if (participantsObj[x].status === "Not Passed" || participantsObj[x].status === "In Progress") {
        acc["Not Passed"] += 1
      } else {
        acc["Pending"] += 1
      }
      return acc
    },
    { "Not Started": 0, Passed: 0, Pending: 0, "Not Passed": 0, Overdue: 0 },
  )

  const totalParticipants = Object.values(participantsObj).length
  const completedStat = activityInfo.testParticipants
    ? Object.values(participantsObj).reduce((acc, x) => {
      if (x.status === "Passed") {
        acc++
      }
      return acc
    }, 0)
    : 0

  const completedPercentage = activityInfo.testParticipants ? Math.floor((completedStat / totalParticipants) * 100) : 0


  return (
    <div
      className="lg:col-start-3 lg:row-end-1 w-[280px]  m-[10px] rounded-[5px] relative border border-transparent hover:border-pareBlue cursor-pointer"
      onClick={toActivityTeamView}>
      <h2 className="sr-only">Summary</h2>
      <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
        <dl className="flex flex-wrap">
          <div className="flex flex-col pl-6 pt-4">
            <FlagIcon aria-hidden="true" className="h-5 w-4 text-pareBlue mr-1" />
            <dt className="max-w-[180px] h-[35px] break-words whitespace-normal text-sm font-semibold text-gray-900 overflow-hidden">{activityInfo.testName}</dt>
          </div>
          <div className="absolute right-0 top-0 self-end px-6 pt-4">
            <dt className="sr-only">Status</dt>
            <ActionsMenuDropdown
              options={[
                { value: 1, label: "Delete" },
                { value: 2, label: "Rename" },
              ]}
              deleteAction={deleteActivity}
              renameAction={renameActivity}></ActionsMenuDropdown>
          </div>
          <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
            <dt className="flex-none">
              <span className="sr-only">Client</span>
              <UserCircleIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
            </dt>
            <dd className="text-sm/6 font-medium text-gray-900">{totalParticipants}</dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6 overflow-hidden text-ellipsis">
            <dt className="flex-none">
              <span className="sr-only">Due date</span>
              <PhoneIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
            </dt>
            <dd className="text-sm/6 text-gray-500 max-h-[20px] overflow-hidden">
              <div>{`${activityInfo.testType}`}</div>
            </dd>
          </div>
          {/* <div className="mt-4 flex w-full flex-none gap-x-4 px-6 overflow-hidden text-ellipsis">
            <dt className="flex-none">
              <span className="sr-only">Due date</span>
              <FingerPrintIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
            </dt>
            <dd className="text-sm/6 text-gray-500">
              <div >{`Created By Manager Bob`}</div>
            </dd>
          </div> */}
        </dl>
        {activityInfo.doneCreation === "Y" ? (
          <div className="mt-6 border-t border-gray-900/5 px-6 py-6">

            <div>
              <label className="CompletionLabel">Completion</label>
              <label className="CompletionPercentageLabel">{`${completedPercentage}%`}</label>
            </div>
          </div>
        ) : (
            <div className="mt-6 border-t border-gray-900/5 px-6 py-6 flex justify-center">
              <div className="CompletionLabel">Creating Module...</div>
            </div>
          )}

      </div>
    </div>
  )
}

export default ActivityBox

import { useEffect, useRef, useState } from "react";
import Message from "../../components/Messages/Message/Message"

const ChatTerminal = ({ scrollContainerRef, scrollButtonRef, stateUpdater, contextCreationMessages, type, loading, uploadContextFile, uploadRubricFile, messagesType }) => {
  const transcriptEndRef = useRef(null);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const calculateDistanceFromBottom = (container) => {
    const { scrollHeight, scrollTop, clientHeight } = container;
    return scrollHeight - scrollTop - clientHeight;
  };

  const handleScrollVisibility = (
    container,
    scrollButton,
  ) => {
    const distanceFromBottom = calculateDistanceFromBottom(container);
    const shouldShowButton = distanceFromBottom > 100;
    setShowScrollButton(shouldShowButton);
    scrollButton.style.display = shouldShowButton ? "flex" : "none";
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    const scrollButton = scrollButtonRef.current;
    if (container && scrollButton) {
      const handleScroll = () =>
        handleScrollVisibility(container, scrollButton);

      handleScroll(); // Check initial state
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [
      scrollContainerRef,
      scrollButtonRef,
      handleScrollVisibility,
    ]);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      const distanceFromBottom = calculateDistanceFromBottom(container);
      const isNearBottom = distanceFromBottom < 100;

      // if (isNearBottom) {
      transcriptEndRef.current ?.scrollIntoView({ behavior: "smooth" });
      // }
    }
  }, [scrollContainerRef, transcriptEndRef, contextCreationMessages, stateUpdater]);

  const scrollToBottom = () => {
    transcriptEndRef.current ?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const scrollButton = scrollButtonRef.current;
    if (scrollButton) {
      scrollButton.addEventListener("click", scrollToBottom);
      return () => scrollButton.removeEventListener("click", scrollToBottom);
    }
  }, [scrollButtonRef]);


  return (
    <>
      <div className="p-4 min-h-[300px] relative">
        {contextCreationMessages.length === 0 ? (
          <div className="flex items-center justify-center h-full text-gray-300 text-sm">
            Get talking to start the conversation!
          </div>
        ) : (
            <div className="space-y-4">
              {contextCreationMessages.map(
                ({ user, text, id }) =>
                  text.trim() !== "" && (
                    <div key={id}>
                      <Message message={{ user, text, id }}
                        type={type}
                        uploadContextFile={uploadContextFile}
                        uploadRubricFile={uploadRubricFile}
                        loading={loading}
                        messagesType={messagesType}></Message>
                    </div>
                  ),
              )}
              <div ref={transcriptEndRef} />
            </div>
          )}
      </div>
    </>
  );
}

export default ChatTerminal
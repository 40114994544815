import { ArrowRightCircleIcon, MapIcon, FlagIcon } from '@heroicons/react/20/solid'

export default function EmptyState({ header, subHeader, buttonLabel, buttonFunction, icon }) {

  const selectedIcon = icon === "learningpath" ? <MapIcon aria-hidden="true" className="mx-auto size-12 text-pareBlue" />
    : <FlagIcon aria-hidden="true" className="mx-auto size-12 text-pareBlue" />

  return (
    <div className="text-center">
      {selectedIcon}
      <h3 className="mt-2 text-sm font-semibold text-gray-900">{header}</h3>
      <p className="mt-1 text-sm text-gray-500">{subHeader}</p>
      <div className="mt-6">
        <button
          type="button"
          className="inline-flex items-center gap-x-2 rounded-md bg-pareBlue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={buttonFunction}
        >
          {buttonLabel}
          <ArrowRightCircleIcon aria-hidden="true" className="-mr-0.5 size-5" />
        </button>
      </div>
    </div>
  )
}

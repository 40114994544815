import React from "react"

const ModuleTable = ({ testName, completionDist, onTestModuleClick }) => {
  return (
    <header className="pt-4 pb-6">
      {/* Header Section */}
      <div className="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-gray-400/10 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8 rounded">
        <div>
          <div className="flex items-center gap-x-3">
            <div className="flex-none rounded-full bg-yellow-400/10 p-1 text-yellow-400">
              <div className="h-2 w-2 rounded-full bg-current"></div>
            </div>
            <h1 className="flex gap-x-3 text-base leading-7">
              <span className="font-semibold text-grey">{testName}</span>
            </h1>
          </div>
          <p className="mt-2 text-xs leading-6 text-gray-400">{`${testName} Summary`}</p>
        </div>
        <button
          className="order-first flex-none rounded bg-pareBlue px-2 py-1 text-s font-medium text-white ring-1 ring-inset ring-pareBlue sm:order-none"
          onClick={onTestModuleClick}>
          Test Module
        </button>
      </div>

      {/* Metrics Section */}
      <div className="grid grid-cols-1 bg-gray-400/10 sm:grid-cols-2 lg:grid-cols-5">
        {[
          { label: "Passed", count: completionDist.Passed, color: "bg-green" },
          { label: "Pending", count: completionDist.Pending, color: "bg-yellow" },
          { label: "In Progress", count: completionDist["Not Passed"], color: "bg-pareBlue" },
          { label: "Overdue", count: completionDist.Overdue, color: "bg-orange" },
          { label: "Not Started", count: completionDist["Not Started"], color: "bg-gray-500" },
        ].map((metric, index) => (
          <div
            key={index}
            className={`border-t border-white/5 px-4 py-6 ${index > 0 ? "sm:border-l" : ""} sm:px-6 lg:px-8`}>
            <p className="text-sm font-medium leading-6 text-gray-400 flex">
              <div className={`flex-none rounded-full p-1 text-yellow-400 flex items-center justify-center`}>
                <div className={`h-2 w-2 ${metric.color} rounded-full bg-current`}></div>
              </div>
              {metric.label}
            </p>
            <p className="mt-2 flex items-baseline gap-x-2">
              <span className="text-4xl font-semibold tracking-tight text-grey">{metric.count}</span>
            </p>
          </div>
        ))}
      </div>
    </header>
  )
}

export default ModuleTable

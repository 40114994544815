import React, { useEffect, useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { Auth, API } from "aws-amplify"
import { useLocation } from "react-router-dom"
import { MultiSelect } from "react-multi-select-component"
import SingleOptionPopUp from "../../components/PopUps/SingleOptionPopUp"
import RubricEditPop from "../../components/PopUps/RubricEditPop"
import LoadingPopUp from "../../components/PopUps/LoadingPopUp"
import OneOffActivityBox from "../../components/OneOffActivityBox/OneOffActivityBox"
import ModuleDetails from "../../components/ActivityTeamViewPage/moduleDetails"
import ModuleTable from "../../components/ActivityTeamViewPage/moduleTable"
import ModuleBreadcrumb from "../../components/ActivityTeamViewPage/moduleBreadcrumb"
import AssignedLearnersTable from "../../components/ActivityTeamViewPage/assignedLearnersTable"
import ModuleLeaderboard from "../../components/ActivityTeamViewPage/moduleLeaderboard"
import "./ActivityTeamViewPage.css"
import { grid } from "ldrs"
import { Tabs, TabsList, TabsTrigger } from "../../@shadcn_components/ui/tabs"
import { UpdateModule } from "../../api/routes"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../@shadcn_components/ui/breadcrumb"
import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions
} from 'mic-check';
import { NotificationContext } from "../../contexts/notificationContext"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { toJS } from "mobx"
import ActivityTeamViewPageSkeleton from "../../components/ActivityTeamViewPage/skeletons"

grid.register()

let alottedTime = null // TODO: typo?

const ActivityTeamViewPage = () => {
  const { triggerNotification } = useContext(NotificationContext)
  const { userStore, teamStore, moduleListStore, recordingListStore } = useStores()
  const navigate = useNavigate()
  const location = useLocation()
  const [teamMemberAnalysisEntryList, setTeamMemberAnalysisEntry] = useState([])
  const [leaderboardRankings, setLeaderboardRankings] = useState([])
  const [AssignPopup, showAssignPopUp] = useState(false)
  const [reps, setReps] = useState([])
  const [dueDate, setDueDate] = useState("")
  const [popUpLoading, setPopUpLoading] = useState(false)
  const [repOptions, setRepOptions] = useState([])
  const [viewAttempts, setViewAttempts] = useState(false)
  const [attemptsArray, setAttemptsArray] = useState([])
  const [attemptsLoading, setAttemptsLoading] = useState(false)
  const [attemptedName, setAttemptedName] = useState("")
  const [currentTab, setCurrentTab] = useState("Overview")
  const [completionDist, setCompletionDist] = useState({})
  const [attemptData, setAttemptData] = useState({})
  const [pageLoading, setPageLoading] = useState(true)
  const [reminderSent, setReminderSent] = useState(false)
  const [showRubricPopUp, setRubricPopUp] = useState({ show: false, mode: null })
  const managerActivityData = moduleListStore.getModule(location.state.activity_id)
  const totalTeam = toJS(teamStore.teamInfo ?.team) || []
  const [currentRubric, setCurrentRubric] = useState(managerActivityData.rubric_history)

  const getAttempts = async (user_id, name, noAttemptsYet) => {
    if (noAttemptsYet.length > 0) {
      setAttemptedName(name)
      setViewAttempts(true)
      setAttemptsLoading(true)
      const attempts = toJS(recordingListStore.getAttempts(location.state.activity_id, user_id)).map(x => toJS(x))
      const attemptCalcs = attempts.reduce(
        (acc, x) => {
          if (x.passed) {
            acc.passed = true
          }
          if (x.createdAt > acc.lastAttemptDate) {
            acc.lastAttemptDate = x.createdAt
          }
          acc.sum += x.conversation_time / 1000
          acc.num += 1
          return acc
        },
        { num: 0, sum: 0, passed: false, lastAttemptDate: 0 },
      )
      setAttemptData(attemptCalcs)
      setAttemptsArray(attempts)
      setAttemptsLoading(false)
    }
  }

  const handlePopUpDueDateChange = e => {
    setDueDate(e.target.value)
  }

  const checkPermissions = async () => {
    try {

      await requestMediaPermissions();
      return true

    } catch (err) {

      const { type, name, message } = err;
      if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
        // browser does not have permission to access camera or microphone
        triggerNotification("error", name, message)
        return false
      } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
        // user didn't allow app to access camera or microphone
        triggerNotification("error", name, message)
        return false
      } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
        // camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
        // (mostly Windows specific problem)
        triggerNotification("error", name, message)
        return false
      } else {
        // not all error types are handled by this library
        triggerNotification("error", name, message)
        return false
      }

    };
  }

  const testModule = async () => {
    if (await checkPermissions()) {
      const moduleObj = location.state
      navigate("/chatRoomPage", { state: moduleObj })
    }
  }



  const AssignToUser = async () => {
    if (reps.length > 0 && !overdueChecker(dueDate)) {
      API.post("ZenoApp", "/assignActivity", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        body: {
          user_id: userStore.user.userId,
          manager_name: userStore.user.name,
          reps: reps,
          company: userStore.user.company,
          activity_id: location.state.activity_id,
          dueDate: dueDate,
        },
      })
      setReps([])
      showAssignPopUp(false)
      triggerNotification("success", "Successfully assigned", `Successfully assigned to module: ${location.state.testName}`)
      moduleListStore.assignRep(location.state.activity_id, reps, dueDate)
    } else {
      triggerNotification("error", "Error ocurred", `Please choose a rep to assign and a valid due date.`)
    }
  }

  const handleDueDateChange = async (userId, new_due_date) => {
    try {
      API.post("ZenoApp", "/changeDueDate", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        body: {
          user_id: userStore.user.userId,
          activity_id: location.state.activity_id,
          manager_id: location.state.manager_id,
          userId,
          new_due_date,
        },
      })
      triggerNotification("success", "Successfully changed due date", `Successfully modified due date to ${new_due_date}`)
    } catch (err) {
      triggerNotification("error", "Error changing due date", `Please retry changing due date`)

    }
  }

  const handleAssignUserClick = () => {
    showAssignPopUp(true)
  }
  const handleBackButtonClick = () => {
    setReps([])
    showAssignPopUp(false)
  }

  useEffect(() => {
    const fetchTeam = async () => {
      if (!teamStore.teamInfo) {
        await teamStore.getTeam(userStore.user.userId, userStore.user.type, userStore.user.company, userStore.user.userId)
      }
    }
    const fetchRecordings = async () => {
      if (!recordingListStore.userRecordings) {
        await recordingListStore.getModuleAttempts(location.state.activity_id, true, userStore.user.userId)
      } else if (!recordingListStore.isAttemptFetched()) {
        await recordingListStore.getModuleAttempts(location.state.activity_id, false, userStore.user.userId)
      }
    }

    const fetchModules = async () => {
      if (!moduleListStore.userModules) {
        if (userStore.user.type === "SDR" || userStore.user.type === "AE") {
          await moduleListStore.getAssignedModules(userStore.user.company, userStore.user.userId)
        } else {
          await moduleListStore.getManagerModules(userStore.user.type, userStore.user.company, userStore.user.userId)
        }
      }
    }

    const fetchData = async () => {
      try {
        await Promise.all([fetchTeam(), fetchModules(), fetchRecordings()])

        // Continue with your logic after ensuring both stores are ready
        const participantsObj = managerActivityData.testParticipants
          ? JSON.parse(managerActivityData.testParticipants)
          : {}
        const participantKeys = Object.keys(participantsObj)
        setRepOptions(
          totalTeam.reduce((acc, x) => {
            if (!participantKeys.includes(x.userId) && x.type !== "Manager" && x.type != "Admin") {
              acc.push({
                label: x.name,
                value: x.userId,
              })
            }
            return acc
          }, []),
        )

        setTeamMemberAnalysisEntry(
          participantKeys.map(x => {
            return Object.assign(participantsObj[x], { user_id: x })
          }),
        )

        setLeaderboardRankings(Object.values(participantsObj).map(rep => {
          const attemptsNum = rep.conversation_id.length
          const name = rep.name
          const email = rep.email
          const overallScore = rep.ai_score.reduce((acc, curr) => acc + curr, 0) / attemptsNum || 0
          const highestScore = rep.ai_score.reduce((acc, curr) => curr > acc ? curr : acc, 0)
          return { name, email, attemptsNum, overallScore, highestScore }
        }))

        setCompletionDist(
          participantKeys.reduce(
            (acc, x) => {
              if (participantsObj[x].status === "Incomplete") {
                if (overdueChecker(participantsObj[x].dueDate)) {
                  acc["Overdue"] += 1
                } else {
                  acc["Not Started"] += 1
                }
              } else if (participantsObj[x].status === "Passed") {
                acc["Passed"] += 1
              } else if (participantsObj[x].status === "Not Passed" || participantsObj[x].status === "In Progress") {
                acc["Not Passed"] += 1
              } else {
                acc["Pending"] += 1
              }
              return acc
            },
            { "Not Started": 0, Passed: 0, Pending: 0, "Not Passed": 0, Overdue: 0 },
          ),
        )

        alottedTime = managerActivityData.timeLimit
      } catch (error) {
        console.error("Error fetching data:", error)
      } finally {
        // Ensure page loading is set to false after both fetches or if already populated
        setPageLoading(false)
      }
    }

    fetchData()
  }, [pageLoading])

  const assignmentPopUp = AssignPopup ? (
    <div className={`frostedBackground is-visible`}>
      {popUpLoading ? (
        <LoadingPopUp header="Assigning Learners..."></LoadingPopUp>
      ) : (
          <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div class="mt-3 text-center sm:mt-5">
                      <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">
                        {"Add Learners to Module"}
                      </h3>
                      <div class="mt-2 mb-4">
                        <p class="text-sm text-gray-500">{` Please select the additional reps within your team that you’d like to assign this to`}</p>
                      </div>
                      <div className="w-full mb-6">
                        <MultiSelect
                          options={repOptions}
                          value={reps}
                          onChange={setReps}
                          labelledBy="Select Reps"></MultiSelect>
                      </div>
                      <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">
                        {"Due Date"}
                      </h3>
                      <div class="mt-2 mb-4">
                        <p class="text-sm text-gray-500">{`Set the due date for new learners`}</p>
                      </div>

                      <input
                        type="date"
                        className="LearnersDueDateInput w-full mb-6"
                        value={dueDate}
                        onChange={handlePopUpDueDateChange}></input>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      class="inline-flex w-full justify-center rounded-md bg-pareBlue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pareBlue sm:col-start-2"
                      onClick={AssignToUser}>
                      {"Assign"}
                    </button>
                    <button
                      type="button"
                      class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={handleBackButtonClick}>
                      {"Cancel"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  ) : null

  const handleBackToTeamViewClick = () => {
    setViewAttempts(false)
  }

  const overdueChecker = dueDate => {
    const stringToDate = new Date(dueDate)
    const epochDueDate = stringToDate.getTime()
    const today = new Date().toDateString()
    const epochToday = new Date(today).getTime()
    return epochToday > epochDueDate
  }

  const sendReminder = async (e, name, email, testName, dueDate) => {
    e.stopPropagation()
    if (!reminderSent) {
      setReminderSent(true)
      try {
        API.post("ZenoApp", "/sendReminder", {
          headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
          body: {
            user_id: userStore.user.userId,
            name,
            email,
            testName,
            dueDate,
          },
        })
        triggerNotification("success", "Successfully sent reminder", `Sent reminder to ${name}`)
      } catch (err) {
        triggerNotification("error", "Error sending reminder", `Please retry sending the reminder`)
      }
    } else {
      triggerNotification("error", "Reminder already sent", `Reminder was already sent to ${name}`)

    }
  }

  const closeRubricPopUp = () => setRubricPopUp({ show: false, mode: null })

  const updateRubric = async () => {
    const res = await UpdateModule(managerActivityData.manager_id, managerActivityData.activity_id, {
      user_id: userStore.user.userId,
      rubric_history: currentRubric.trim()
    })
    if (res.kind === 'ok') {
      moduleListStore.updateModuleField(managerActivityData.activity_id, "rubric_history", currentRubric)
      triggerNotification("success", "Update Successful", "Rubric has been successfully updated.")
    } else {
      triggerNotification("error", "Error updating rubric", "There was an issue updating the rubric. Please try again.")
    }
    closeRubricPopUp()
  }



  const rubricPopUp = showRubricPopUp.show && showRubricPopUp.mode === "view" ?
    <SingleOptionPopUp
      header="Custom Rubric"
      body={managerActivityData.rubric_history}
      buttonLabel="Close"
      buttonFunction={closeRubricPopUp}></SingleOptionPopUp>
    : showRubricPopUp.show && showRubricPopUp.mode === "edit" ?
      <RubricEditPop
        header="Custom Rubric"
        body={managerActivityData.rubric_history}
        leftButtonLabel="Cancel"
        leftButtonFunction={closeRubricPopUp}
        rightButtonLabel="Save"
        rightButtonFunction={updateRubric}
        setCurrentRubric={setCurrentRubric}></RubricEditPop>
      : null

  return (
    <>
      {pageLoading ? (
        <ActivityTeamViewPageSkeleton></ActivityTeamViewPageSkeleton>
      ) : (
          <div class="w-full h-full flex flex-col px-8 md:px-20 pt-8">
            <>
              <div className="mb-8">
                {viewAttempts ? (
                  <div>
                    <Breadcrumb>
                      <BreadcrumbList>
                        <BreadcrumbItem>
                          <BreadcrumbLink href="/assignmentsPage">Modules</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                          <BreadcrumbPage>{`${attemptedName} Attempts`}</BreadcrumbPage>
                        </BreadcrumbItem>
                      </BreadcrumbList>
                    </Breadcrumb>
                    <div>
                      <header class="pt-4 pb-6">
                        <div class="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-gray-400/10 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8 rounded">
                          <div>
                            <div class="flex items-center gap-x-3">
                              <div class="flex-none rounded-full bg-yellow-400/10 p-1 text-yellow-400">
                                <div class="h-2 w-2 rounded-full bg-current"></div>
                              </div>

                              <h1 class="flex gap-x-3 text-base leading-7">
                                <span class="font-semibold text-grey">{location.state.testName}</span>
                              </h1>
                            </div>
                            <p class="mt-2 text-xs leading-6 text-gray-400">{`${location.state.testName} Attempts by ${attemptedName}`}</p>
                          </div>
                          <button
                            class="order-first flex-none rounded bg-pareBlue px-2 py-1 text-s font-medium text-white ring-1 ring-inset ring-pareBlue sm:order-none"
                            onClick={handleBackToTeamViewClick}>
                            Back to team view
                        </button>
                        </div>

                        <div class="grid grid-cols-1 bg-gray-400/10 sm:grid-cols-2 lg:grid-cols-4">
                          <div class="border-t border-white/5 px-4 py-6 sm:px-6 lg:px-8">
                            <p class="text-sm font-medium leading-6 text-gray-400 flex">
                              Passed
                          </p>
                            <p class="mt-2 flex items-baseline gap-x-2">
                              <span class="text-4xl font-semibold tracking-tight text-grey">
                                {attemptData.passed ? "Yes" : "No"}
                              </span>
                            </p>
                          </div>
                          <div class="border-t border-white/5 px-4 py-6 sm:border-l sm:px-6 lg:px-8">
                            <p class="text-sm font-medium leading-6 text-gray-400 flex">Average Attempt Time</p>
                            <p class="mt-2 flex items-baseline gap-x-2">
                              <span class="text-4xl font-semibold tracking-tight text-grey">
                                {Math.floor(attemptData.sum / attemptData.num)}
                              </span>
                              <span class="text-sm text-gray-400">secs</span>
                            </p>
                          </div>
                          <div class="border-t border-white/5 px-4 py-6 sm:px-6 lg:border-l lg:px-8">
                            <p class="text-sm font-medium leading-6 text-gray-400 flex">Last Attempt Date</p>
                            <p class="mt-2 flex items-baseline gap-x-2">
                              <span class="text-4xl font-semibold tracking-tight text-grey">
                                {new Date(attemptData.lastAttemptDate).toLocaleDateString()}
                              </span>
                            </p>
                          </div>
                          <div class="border-t border-white/5 px-4 py-6 sm:border-l sm:px-6 lg:px-8">
                            <p class="text-sm font-medium leading-6 text-gray-400 flex">Total Attempts</p>
                            <p class="mt-2 flex items-baseline gap-x-2">
                              <span class="text-4xl font-semibold tracking-tight text-grey">{attemptData.num}</span>
                            </p>
                          </div>
                        </div>
                      </header>
                      {attemptsLoading ? (
                        <div className="flex-1 px-8 flex flex-row content-center justify-center">
                          <l-grid size="120" speed="1.4" color="#3045FF"></l-grid>
                        </div>
                      ) : (
                          <div className="w-full flex flex-row flex-wrap">
                            {attemptsArray.map((x, i) => (
                              <OneOffActivityBox
                                oneOffActivityInfo={x}
                                moduleInfo={managerActivityData}></OneOffActivityBox>
                            ))}
                          </div>
                        )}
                    </div>
                  </div>
                ) : (
                    <div>
                      <ModuleBreadcrumb
                        basePath="/assignmentsPage"
                        baseLabel="Modules"
                        currentPage={location.state.testName}
                      />
                      <ModuleTable
                        testName={location.state.testName}
                        completionDist={completionDist}
                        onTestModuleClick={testModule}
                      />
                      <div class="flex flex-col items-start justify-between py-2 sm:flex-row sm:items-center rounded">
                        <div>
                          <Tabs
                            defaultValue={currentTab}
                            className="w-[100%]"
                            onValueChange={str => {
                              setCurrentTab(str)
                              setViewAttempts(false)
                            }}>
                            <TabsList>
                              <TabsTrigger value="Overview">Overview</TabsTrigger>
                              <TabsTrigger value="Assigned Learners">Assigned Learners</TabsTrigger>
                              <TabsTrigger value="Leaderboard">Leaderboard</TabsTrigger>
                            </TabsList>
                          </Tabs>
                        </div>
                        {userStore.user.userId === location.state.manager_id ? (
                          <button
                            class="order-first flex-none rounded-full bg-indigo-400/10 px-2 py-1 text-s font-medium text-pareBlue ring-1 ring-inset ring-pareBlue sm:order-none"
                            onClick={handleAssignUserClick}>
                            Assign Learners
                      </button>
                        ) : null}
                      </div>
                      {currentTab === "Overview" ? (
                        <ModuleDetails
                          moduleCatInfo={managerActivityData}
                          setRubricPopUp={setRubricPopUp}
                        />
                      ) : currentTab === "Assigned Learners" ? (
                        <AssignedLearnersTable
                          teamMemberAnalysisEntryList={teamMemberAnalysisEntryList}
                          getAttempts={getAttempts}
                          overdueChecker={overdueChecker}
                          handleDueDateChange={handleDueDateChange}
                          sendReminder={sendReminder}
                          testName={managerActivityData.testName}
                          dueDate={managerActivityData.dueDate}
                        />
                      ) : <ModuleLeaderboard
                        leaderboardRankings={leaderboardRankings}>
                          </ModuleLeaderboard>}
                    </div>
                  )}
              </div>
            </>
            {rubricPopUp}
            {assignmentPopUp}
          </div>
        )}
    </>
  )
}

export default observer(ActivityTeamViewPage)

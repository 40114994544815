import { types, Instance, SnapshotOut } from "mobx-state-tree"
import { UserStoreModel, UserStore } from "../user-store/user-store"
import { TeamStoreModel, TeamStore } from "../team-store/team-store"
import { ModuleListStoreModel, ModuleListStore } from "../module-store/module-store"
import { RecordingListStoreModel, RecordingListStore } from "../recording-store/recording-store"
import { LearningPathListStoreModel, LearningPathStore } from "../lp-store/lp-store"

export const RootStoreModel = types.model("RootStore").props({
  userStore: types.optional(UserStoreModel, {} as any),
  teamStore: types.optional(TeamStoreModel, {} as any),
  moduleListStore: types.optional(ModuleListStoreModel, {} as any),
  recordingListStore: types.optional(RecordingListStoreModel, {} as any),
  learningPathStore: types.optional(LearningPathListStoreModel, {} as any),
})
export interface RootStore extends Instance<typeof RootStoreModel> {}
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}

// This is needed to break RootStore's circular dependencies
export type RootStoreType = {
  userStore: UserStore
  teamStore: TeamStore
  moduleListStore: ModuleListStore
  recordingListStore: RecordingListStore
  learningPathStore: LearningPathStore
}

import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import awsmobile from "../../aws-exports"
import { Auth, API } from "aws-amplify"
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../../@shadcn_components/ui/sheet"
import "./OneOffActivityBox.css"

Auth.configure(awsmobile)

const OneOffActivityBox = ({ oneOffActivityInfo, moduleInfo }) => {
  let navigate = useNavigate()
  const [video, setVideo] = useState(oneOffActivityInfo.video_url)

  const toRecordingDetails = () => {
    navigate("/moduleAnalysisPage", { state: Object.assign(oneOffActivityInfo, moduleInfo) })
  }
  const teamMemberIconStyle = {
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    backgroundColor: `grey`,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  }
  const RecordingStatusLabelStyle = {
    background:
      oneOffActivityInfo.status === "Pending"
        ? "rgba(255, 199, 0)"
        : oneOffActivityInfo.status === "Passed"
          ? "rgba(57, 202, 34)"
          : "rgba(255, 94, 94)",
    fontSize: "9px",
    color: "white",
    borderRadius: "5px",
    position: "absolute",
    top: "41%",
    right: "2.5%",
    padding: "5px",
    minWidth: "60px",
    display: "inline-block",
    textAlign: "center",
    fontWeight: 500,
  }
  const initials =
    oneOffActivityInfo.name.split(" ")[0].toUpperCase().charAt(0) +
    oneOffActivityInfo.name.split(" ")[1].toUpperCase().charAt(0)

  return (
    <div className="ActivityRecordingBox" onClick={toRecordingDetails}>
      <div className="RecordingVideoContainer">
        <video src={video} height="100%" width="100%" borderTopRadius="5px"></video>
      </div>
      <div className="videoShader"></div>
      <div style={RecordingStatusLabelStyle}>{`${oneOffActivityInfo.status}`}</div>
      <label className="OneOffActivityTitle">{oneOffActivityInfo.convo_title}</label>
      <div className="OneOffActivityIconContainer">
        <div style={teamMemberIconStyle}>{initials}</div>
      </div>
      <div className="OneOffDueDateIcon"></div>
      <label className="OneOffActivityDateLabel">{`${new Date(oneOffActivityInfo.createdAt).toLocaleDateString()}`}</label>
      <div className="OneOffTimeLimitIcon"></div>
      <label className="OneOffActivityBoxDurationLabel">{`Duration: ${oneOffActivityInfo.conversation_time_str}`}</label>
      <div className="CommentIcon"></div>
      <div className="ViewCommentsButton" onClick={e => e.stopPropagation()}>
        <Sheet>
          <SheetTrigger>{oneOffActivityInfo.comments ? "View Comments" : "No Comments"}</SheetTrigger>
          <SheetContent>
            <SheetHeader>
              <SheetTitle>Comments</SheetTitle>
              <SheetDescription>{oneOffActivityInfo.comments}</SheetDescription>
            </SheetHeader>
          </SheetContent>
        </Sheet>
      </div>
    </div>
  )
}

export default OneOffActivityBox

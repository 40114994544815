import React, { useState, useEffect } from "react"
import IndividualActivityBox from "../../components/IndividualActivityBox/IndividualActivityBox"
import { grid } from "ldrs"
import { useNavigate } from "react-router-dom"
import { Tabs, TabsList, TabsTrigger } from "../../@shadcn_components/ui/tabs"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { toJS } from "mobx"
import AssignmentsPageSkeleton from "../../components/AssignmentsPage/skeletons"
import { FlagIcon } from "@heroicons/react/20/solid"


grid.register()

const AssignmentsPage = () => {
  const { userStore, moduleListStore } = useStores()
  const [pageLoading, setPageLoading] = useState(true)
  const [searchBarInput, setSearchBarInput] = useState("")
  const [selectedFilter, setSelectedFilter] = useState("All")
  const modules = toJS(moduleListStore.userModules ?.moduleList) || []
  const today = new Date().toDateString()
  const epochToday = new Date(today).getTime()

  useEffect(() => {
    const fetchModules = async () => {
      if (!moduleListStore.userModules) {
        if (userStore.user.type === "SDR" || userStore.user.type === "AE") {
          await moduleListStore.getAssignedModules(userStore.user.company, userStore.user.userId)
        } else {
          await moduleListStore.getManagerModules(userStore.user.type, userStore.user.company, userStore.user.userId)
        }
      }
      setPageLoading(false)
    }
    fetchModules()
  }, [])

  const filteredActivities = modules.filter(activity => {
    // Filter out anything that doesn't match search
    if (searchBarInput.length > 0) {
      if (!activity.testName.match(new RegExp(`^${searchBarInput}.*`, "i"))) {
        return false
      }
    }

    const individualDueDate = JSON.parse(activity.testParticipants)[userStore.user.userId].dueDate
    const stringToDate = new Date(individualDueDate)
    const epochDueDate = stringToDate.getTime()
    const participantObj = JSON.parse(activity.testParticipants)

    // Passed
    if (selectedFilter === "Passed" && participantObj[userStore.user.userId].status !== "Passed") {
      return false
    }

    // Overdue
    if (
      selectedFilter === "Overdue" &&
      ((epochDueDate > epochToday && participantObj[userStore.user.userId].status === "Incomplete") ||
        participantObj[userStore.user.userId].status === "Passed" ||
        participantObj[userStore.user.userId].status === "Pending" ||
        participantObj[userStore.user.userId].status === "In Progress" ||
        participantObj[userStore.user.userId].status === "Not Passed")
    ) {
      return false
    }

    // Not Passed
    if (selectedFilter === "Not Passed" && participantObj[userStore.user.userId].status !== "Not Passed") {
      return false
    }

    return true
  })

  return (
    <>
      {pageLoading ? (
        <AssignmentsPageSkeleton></AssignmentsPageSkeleton>
      ) : (
          <div className="w-full h-full flex flex-col px-8">
            <div class="sm:flex sm:items-center">
              <div class="sm:flex-auto">
                <FlagIcon aria-hidden="true" className="h-5 w-4 text-pareBlue mr-1" />
                <h1 class="text-base font-semibold leading-6 text-gray-900">Module Library</h1>
                <p class="mt-2 text-sm text-gray-700">View and complete assigned role play modules</p>
              </div>
            </div>
            <div className="mb-4 mt-4">
              <Tabs defaultValue="All" className="w-[100%]" onValueChange={setSelectedFilter}>
                <TabsList>
                  <TabsTrigger value="All">All</TabsTrigger>
                  <TabsTrigger value="Passed">Passed</TabsTrigger>
                  <TabsTrigger value="Overdue">Overdue</TabsTrigger>
                  <TabsTrigger value="Not Passed">Not Passed</TabsTrigger>
                </TabsList>
              </Tabs>
            </div>
            {filteredActivities ?.length === 0 ? (
              <div className="flex flex-col items-center mt-20">
                <p className="text-sm text-muted-foreground">No Active Modules</p>
                <p className="text-sm text-muted-foreground">Please wait for your manager to create and assign</p>
              </div>
            ) : (
                <div className="w-full h-full rounded-md flex flex-row flex-wrap content-start">
                  {filteredActivities ?.map(activity => (
                    <IndividualActivityBox
                      type={"FlexBox"}
                      activityInfo={activity}
                      key={`activity_card_${activity.activity_id}`}
                    />
                  ))}
                </div>
              )}
          </div>
        )}
    </>
  )
}

export default observer(AssignmentsPage)

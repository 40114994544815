import React, { useState, useEffect } from "react"
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { ChevronDownIcon } from '@heroicons/react/16/solid'

export default function PersonaCreationPopUp({ setShowCreationPopUp }) {
  const textArea = <div>
    {/* <label for="comment" class="block text-sm/6 font-medium text-gray-900">Add your comment</label> */}
    <div class="mt-2">
      <textarea rows="4" name="comment" id="comment" class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"></textarea>
    </div>
  </div>

  return (
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all">

            <form>
              <div className="space-y-12 sm:space-y-16">
                <div>
                  <h2 className="text-base/7 font-semibold text-gray-900">Persona Information</h2>
                  <p className="mt-1 max-w-2xl text-sm/6 text-gray-600">Fill out the persona information below to describe who the character is</p>

                  <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                      <label htmlFor="first-name" className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5">
                        Persona name
              </label>
                      <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <input
                          id="first-name"
                          name="first-name"
                          type="text"
                          autoComplete="given-name"
                          className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:max-w-xs sm:text-sm/6"
                        />
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                      <label htmlFor="street-address" className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5">
                        Persona company
              </label>
                      <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <input
                          id="street-address"
                          name="street-address"
                          type="text"
                          autoComplete="street-address"
                          className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:max-w-xl sm:text-sm/6"
                        />
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                      <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5">
                        Persona personality
              </label>
                      <div className="mt-2 sm:col-span-2 sm:mt-0">
                        {textArea}
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                      <label htmlFor="country" className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5">
                        Persona pain points

              </label>
                      <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <div className="grid grid-cols-1 sm:max-w-xs">
                          <select
                            id="country"
                            name="country"
                            autoComplete="country-name"
                            className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                          >
                            <option>United States</option>
                            <option>Canada</option>
                            <option>Mexico</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                      <label htmlFor="country" className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5">
                        Persona voice

              </label>
                      <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <div className="grid grid-cols-1 sm:max-w-xs">
                          <select
                            id="country"
                            name="country"
                            autoComplete="country-name"
                            className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                          >
                            <option>Male 1</option>
                            <option>Male 2</option>
                            <option>Female 1</option>
                          </select>

                        </div>
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                      <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5">
                        Any additional persona information
              </label>
                      <div className="mt-2 sm:col-span-2 sm:mt-0">
                        {textArea}
                      </div>
                    </div>
                    <fieldset>
                      <legend className="sr-only">Select Difficulty</legend>
                      <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4 sm:py-6">
                        <div aria-hidden="true" className="text-sm/6 font-semibold text-gray-900">
                          Select Difficulty
                </div>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <div className="max-w-lg">
                            {/* <p className="text-sm/6 text-gray-600">These are delivered via SMS to your mobile phone.</p> */}
                            <div className="mt-6 space-y-6">
                              <div className="flex items-center gap-x-3">
                                <input
                                  defaultChecked
                                  id="push-everything"
                                  name="push-notifications"
                                  type="radio"
                                  className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-indigo-600 checked:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
                                />
                                <label htmlFor="push-everything" className="block text-sm/6 font-medium text-gray-900">
                                  Easy
                        </label>
                              </div>
                              <div className="flex items-center gap-x-3">
                                <input
                                  id="push-email"
                                  name="push-notifications"
                                  type="radio"
                                  className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-indigo-600 checked:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
                                />
                                <label htmlFor="push-email" className="block text-sm/6 font-medium text-gray-900">
                                  Intermediate
                        </label>
                              </div>
                              <div className="flex items-center gap-x-3">
                                <input
                                  id="push-nothing"
                                  name="push-notifications"
                                  type="radio"
                                  className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-indigo-600 checked:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
                                />
                                <label htmlFor="push-nothing" className="block text-sm/6 font-medium text-gray-900">
                                  Challenging
                        </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>

                  </div>
                </div>

                <div>
                </div>
              </div>

              <div className="mt-6 flex items-center justify-end gap-x-6">
                <button type="button" className="text-sm/6 font-semibold text-gray-900" onClick={() => setShowCreationPopUp(false)}>
                  Cancel
        </button>
                <button
                  type="submit"
                  className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Save
        </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from "react"
import { CogIcon, PhoneIcon } from '@heroicons/react/20/solid'

const PersonaBox = ({ personaInfo, setOpenPersonaPreview }) => {

  const person =
  {
    name: 'Jane Cooper',
    title: 'Paradigm Representative',
    difficulty: 'Easy Going',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  }

  return (
    <li
      key={person.email}
      className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
    >
      <div className="flex flex-1 flex-col p-8">
        <img alt="" src={person.imageUrl} className="mx-auto size-32 shrink-0 rounded-full" />
        <h3 className="mt-6 text-sm font-medium text-gray-900">{person.name}</h3>
        <dl className="mt-1 flex grow flex-col justify-between">
          <dt className="sr-only">Title</dt>
          <dd className="text-sm text-gray-500">{person.title}</dd>
          <dt className="sr-only">Role</dt>
          <dd className="mt-3">
            <span className="inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-pareBlue ring-1 ring-inset ring-blue-600/20">
              {person.difficulty}
            </span>
          </dd>
        </dl>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          {/* <div className="flex w-0 flex-1">
            <a
              href={`mailto:${person.email}`}
              className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
            >
              <CogIcon aria-hidden="true" className="size-5 text-gray-400" />
              Edit
                  </a>
          </div> */}
          <div className="-ml-px flex w-0 flex-1 cursor-pointer" onClick={() => setOpenPersonaPreview(true)}>
            <a
              className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
            >
              <PhoneIcon aria-hidden="true" className="size-5 text-gray-400" />
              Call
                  </a>
          </div>
        </div>
      </div>
    </li>

  )
}

export default PersonaBox

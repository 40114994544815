import React, { useEffect, useState, useRef } from "react"
import { v4 as uuid } from "uuid"
import RecordRTC from "recordrtc"
import { Auth, API } from "aws-amplify"
import ysFixWebmDuration from "fix-webm-duration"
import { useStores } from "../../models/root-store"
import "./Dictaphone.css"

const ENDPOINT = process.env.REACT_APP_WS_ENDPOINT

let socket
let aai_socket
let isRecording = null
let audioFileID = uuid()
let startTime = null

const DictaphoneProto = ({
  updateTranscript,
  setSpeaker,
  setDuration,
  record,
  getVideoId,
  endSession,
  getVideoForm,
  appendScreenShareStream,
  StartTime,
  getAudioId,
  getAudioForm,
  appendBotStream,
  recordingType,
  signalHangup,
  captureFrame,
}) => {

  const hostGreeted = useRef(false)
  const [convoStarted, setConvoStarted] = useState(false)
  const mediaRecorder = useRef(null)
  const botAudioRecorder = useRef(null)
  const SpeakerTurnNum = useRef(0)
  const audioChunks = useRef(null)
  const videoRef = useRef()
  const mediaRecorderVideo = useRef()
  const aai_recorder = useRef()
  const [videoChunks, setVideoChunks] = useState([])
  const [noUserPermission, setNoUserPermission] = useState(true)
  const botAudioContext = useRef(null)
  const botAudioDestination = useRef(null)
  const context = useRef(null)
  const source1 = useRef(null)
  const source2 = useRef(null)
  const botAudioStream = useRef(null)
  const destination = useRef(null)
  const combinedStream = useRef(null)
  const screenStream = useRef(null)
  const UserDataStream = useRef(null)
  const { userStore, currentRoleplayStore } = useStores()
  const { userId, name, type, company } = userStore.user || {}
  const { additionalInstructions, callType, conversationContext, assistant_id, prospectName, systemPrompt } = currentRoleplayStore.currentRoleplay || {}
  const curr_user = userId
  const nextAudioRef = useRef(null)
  const ws_endpoint =
    recordingType === "module" || type === "Manager" ? "sendmessagecustom" : "sendmessage"

  if (endSession) {
    if (nextAudioRef.current) {
      nextAudioRef.current.audio.pause()
      nextAudioRef.current = null
    }
  }

  useEffect(() => {
    context.current = new AudioContext()
    destination.current = context.current.createMediaStreamDestination()
    botAudioContext.current = new AudioContext()
    botAudioDestination.current = context.current.createMediaStreamDestination()

    if (!socket || socket.readyState == 3) {
      socket = new WebSocket(ENDPOINT)
    }
    const audioArrayOrdered = []
    let totalBotSpeakerTurnText = ""
    let currentAudio = null
    let sentenceIdx = 0
    let finalSentenceIdx = 1000
    let hangUpFlag = false

    socket.onopen = () => {
      console.log("Succesfully opened WS")
      const initialMessageObj =
        recordingType === "module" || type === "Manager"
          ? {
            user: curr_user,
            message_content: null,
            convoStart: true,
            convoEnd: false,
            additionalInstructions: additionalInstructions,
            assistant_id: assistant_id,
            convoId: audioFileID,
            order: SpeakerTurnNum.current,
            companyTag: company,
            callType: callType,
          }
          : {
            user: curr_user,
            message_content: null,
            convoStart: true,
            convoEnd: false,
            context: conversationContext,
            systemPrompt: systemPrompt,
            convoId: audioFileID,
            order: SpeakerTurnNum.current,
            companyTag: company,
            callType: callType,
          }
      const payload = { action: ws_endpoint, message: initialMessageObj }
      socket.send(JSON.stringify(payload))
    }
    socket.onmessage = async event => {
      const s3UploadObj = JSON.parse(event.data)
      console.log(s3UploadObj)
      if (s3UploadObj.botSpeakerTurnText === "DONE") {
        finalSentenceIdx = s3UploadObj.orderIdx
      }
      if (s3UploadObj.botSpeakerTurnText.trim().match(/^I.m going to hang up now./i)) {
        hangUpFlag = true
      }
      if (s3UploadObj.body !== "Message sent" && s3UploadObj.botSpeakerTurnText !== "DONE") {
        const botSpeakerTurnText = s3UploadObj.botSpeakerTurnText
        totalBotSpeakerTurnText += botSpeakerTurnText
        const audio = new Audio(s3UploadObj.url)

        audio.crossOrigin = "anonymous"
        audio.oncanplay = function () {
          const stream = audio.captureStream()
          const source = context.current.createMediaStreamSource(stream)
          const desktopGain = context.current.createGain()
          desktopGain.gain.value = 0.7
          source.connect(desktopGain).connect(destination.current)
          source.connect(desktopGain).connect(botAudioDestination.current)
        }

        audioArrayOrdered.push({ audio: audio, orderIdx: botSpeakerTurnText })

        if (!currentAudio) {
          await playNextAudio()
        }
        async function playNextAudio() {
          if (audioArrayOrdered.length == 0 && sentenceIdx == finalSentenceIdx) {
            updateTranscript({ text: totalBotSpeakerTurnText, user: "bot" })
            if (hangUpFlag) {
              signalHangup()
            }
            setSpeaker("user")
            SpeakerTurnNum.current += 1
            run()
            totalBotSpeakerTurnText = ""
            sentenceIdx = 0
            finalSentenceIdx = 1000
          }
          nextAudioRef.current = audioArrayOrdered.shift()
          if (nextAudioRef.current) {
            nextAudioRef.current.audio.addEventListener("ended", () => {
              sentenceIdx++
              currentAudio = null
              playNextAudio()
            })

            nextAudioRef.current.audio.play()
            currentAudio = nextAudioRef.current.audio
          }
        }
      }
    }
    getCameraPermission()

    if (window.performance) {
      if (performance.navigation.type == 1) {
        console.log("This page is reloaded")
      } else {
        console.log("This page is not reloaded")
      }
    }
    return () => {
      const msg =
        recordingType === "module" || type === "Manager"
          ? {
            user: curr_user,
            message_content: null,
            convoStart: true,
            convoEnd: true,
            additionalInstructions: additionalInstructions,
            assistant_id: assistant_id,
            convoId: audioFileID,
            order: SpeakerTurnNum.current,
            companyTag: company,
            callType: callType,
          }
          : {
            user: curr_user,
            message_content: null,
            convoStart: true,
            convoEnd: true,
            context: conversationContext,
            convoId: audioFileID,
            order: SpeakerTurnNum.current,
            companyTag: company,
            callType: callType,
          }
      const endConvoPayload = { action: ws_endpoint, message: msg }
      if (socket.readyState == 1) {
        socket.send(JSON.stringify(endConvoPayload))
        socket.close()
      }
      videoRef.current = null
      mediaRecorder.current = null
      botAudioRecorder.current = null
      hostGreeted.current = null
      audioChunks.current = null
      aai_recorder.current = null
      SpeakerTurnNum.current = null
      if (source1.current) {
        source1.current.disconnect()
        source1.current = null
      }
      if (source2.current) {
        source2.current.disconnect()
        source2.current = null
      }
      if (context.current) {
        context.current.close()
        context.current = null
      }
      if (botAudioContext.current) {
        botAudioContext.current.close()
        botAudioContext.current = null
      }
      if (combinedStream.current) {
        combinedStream.current.getTracks().forEach(function (track) {
          track.stop()
        })
      }
      combinedStream.current = null
      if (screenStream.current) {
        screenStream.current.getTracks().forEach(function (track) {
          track.stop()
        })
      }
      screenStream.current = null
      if (UserDataStream.current) {
        UserDataStream.current.getTracks().forEach(function (track) {
          track.stop()
        })
      }
      UserDataStream.current = null
      if (destination.current) {
        destination.current.stream.getTracks().forEach(function (track) {
          track.stop()
        })
      }
      destination.current = null
      if (botAudioDestination.current) {
        botAudioDestination.current.stream.getTracks().forEach(function (track) {
          track.stop()
        })
      }
      botAudioDestination.current = null
      if (botAudioStream.current) {
        botAudioStream.current.getTracks().forEach(function (track) {
          track.stop()
        })
      }
      botAudioStream.current = null
    }
  }, [])

  const sendFrame = async () => {
    let file_key = null
    try {
      const signedUrlAudioUploadObj = await API.get("ZenoApp", "/getSignedUrlFrame", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        queryStringParameters: {
          user_id: userId,
          conversation_id: audioFileID,
          speaker_turn: SpeakerTurnNum.current,
        },
      })
      file_key = signedUrlAudioUploadObj.file_key
      const frame = await captureFrame()
      console.log("FRAME CAPTURED")
      // const FrameFile = new File([frame],`frame_${SpeakerTurnNum.current}.png`)
      await fetch(signedUrlAudioUploadObj.signedUploadUrl, {
        method: "PUT",
        headers: { "Content-Type": "image/png" },
        body: frame,
      })
      console.log("FRAME SENT")
    } catch (e) {
      console.log(e)
    }

    return file_key
  }
  const startConversation = async () => {
    if (!noUserPermission && !hostGreeted.current) {
      SpeakerTurnNum.current += 1
      hostGreeted.current = true
      startRecordingVideo()
      isRecording = true
      StartTime(true)
      setConvoStarted(true)

      setSpeaker("bot")
      const response =
        recordingType === "module" || type === "Manager"
          ? {
            user: curr_user,
            message_content: "",
            convoStart: false,
            convoEnd: false,
            assistant_id: assistant_id,
            additionalInstructions: additionalInstructions,
            convoId: audioFileID,
            order: SpeakerTurnNum.current,
            voice: prospectName,
            companyTag: company,
          }
          : {
            user: curr_user,
            message_content: "",
            convoStart: false,
            convoEnd: false,
            context: conversationContext,
            initialUserMessage: systemPrompt,
            convoId: audioFileID,
            order: SpeakerTurnNum.current,
            voice: prospectName,
            companyTag: company,
          }
      const startPayload = { action: ws_endpoint, message: response }
      socket.send(JSON.stringify(startPayload))
      // }
    } else {
      alert("Permission has not been given. Please share current tab and make sure the Share Audio Option is checked.")
      getCameraPermission()
    }
  }

  const getCameraPermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        screenStream.current = await navigator.mediaDevices.getDisplayMedia({
          video: {
            mediaSource: "screen",
          },
          audio: false,
          surfaceSwitching: "include",
        })

        UserDataStream.current = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        })
        const source = context.current.createMediaStreamSource(UserDataStream.current)
        const desktopGain = context.current.createGain()
        desktopGain.gain.value = 0.7
        source.connect(desktopGain).connect(destination.current)

        combinedStream.current = new MediaStream([
          ...screenStream.current.getVideoTracks(),
          ...destination.current.stream.getAudioTracks(),
        ])
        mediaRecorderVideo.current = new MediaRecorder(combinedStream.current, { type: "video/webm" })
        botAudioStream.current = new MediaStream(botAudioDestination.current.stream.getAudioTracks())
        botAudioRecorder.current = new MediaRecorder(botAudioStream.current, { type: "audio/webm" })
        botAudioRecorder.current.start()
        appendBotStream(botAudioRecorder.current)
        appendScreenShareStream(new MediaStream(screenStream.current.getVideoTracks()))

        setNoUserPermission(false)
      } catch (err) {
        setNoUserPermission(true)
        alert(err.message)
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.")
    }
  }

  const mergeAudioStreams = (desktopStream, voiceStream) => {
    context.current = new AudioContext()
    const destination = context.current.createMediaStreamDestination()
    let hasDesktop = false
    let hasVoice = false
    if (desktopStream && desktopStream.getAudioTracks().length > 0) {
      source1.current = context.current.createMediaStreamSource(desktopStream)
      const desktopGain = context.current.createGain()
      desktopGain.gain.value = 0.7
      source1.current.connect(desktopGain).connect(destination)
      hasDesktop = true
    }

    if (voiceStream && voiceStream.getAudioTracks().length > 0) {
      source2.current = context.current.createMediaStreamSource(voiceStream)
      const voiceGain = context.current.createGain()
      voiceGain.gain.value = 0.7
      source2.current.connect(voiceGain).connect(destination)
      hasVoice = true
    }
    return hasDesktop || hasVoice ? destination.stream.getAudioTracks() : []
  }

  const startRecording = async () => {
    botAudioRecorder.current.start()
    // mediaRecorder.current.start();
    let localAudioChunks = []
    botAudioRecorder.current.ondataavailable = event => {
      if (typeof event.data === "undefined") return
      if (event.data.size === 0) return
      localAudioChunks.push(event.data)
    }
    audioChunks.current = localAudioChunks
  }

  const startRecordingVideo = async () => {
    console.log("recordingVideo")
    mediaRecorderVideo.current.start()
    startTime = Date.now()
    let localVideoChunks = []
    mediaRecorderVideo.current.ondataavailable = event => {
      if (typeof event.data === "undefined") return
      if (event.data.size === 0) return
      localVideoChunks.push(event.data)
    }
    setVideoChunks(localVideoChunks)
  }

  const stopRecording = () => {
    console.log("stopped recording")
    //stops the recording instance
    botAudioRecorder.current.stop()
    // mediaRecorder.current.stop();
    botAudioRecorder.current.onstop = () => {
      //creates a blob file from the audiochunks data
      const audioBlob = new Blob(audioChunks.current, { type: "audio/webm" })
      //creates a playable URL from the blob file.
      const formData = new FormData()
      formData.append("audio", audioBlob)
      getAudioId(audioFileID)
      getAudioForm(audioChunks.current[0])

      audioChunks.current = []
    }
  }

  const stopRecordingVideo = () => {
    console.log("stopped recording Video")
    botAudioRecorder.current.stop()
    mediaRecorderVideo.current.stop()
    mediaRecorderVideo.current.onstop = () => {
      const video_id = uuid()
      var duration = Date.now() - startTime
      var buggyBlob = new Blob(videoChunks, { type: "video/webm" })
      ysFixWebmDuration(buggyBlob, duration, { logger: false }).then(function (fixedBlob) {
        getVideoId(video_id)
        getVideoForm(new File([fixedBlob], `${video_id}.webm`))
        setVideoChunks([])
        setDuration(duration)
      })
    }
    mediaRecorderVideo.current = null
  }

  useEffect(() => {
    if (!record && hostGreeted.current === true) {
      stopRecordingVideo()
      // stopListening();
      isRecording = false
      run()
    }
  }, [record])

  const run = async () => {
    let lastFinalTranscriptTime = 0
    let potentialFinalUtterance = false
    let finalTranscriptText = ""
    if (isRecording === false) {
      if (aai_socket) {
        aai_socket.send(JSON.stringify({ terminate_session: true }))
        aai_socket.close()
        aai_socket = null
      }

      if (aai_recorder.current) {
        aai_recorder.current.stopRecording()
        aai_recorder.current = null
      }
    } else {
      const tokenRequest = await API.get("ZenoApp", "/getAaiToken", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        queryStringParameters: {
          user_id: curr_user,
        },
      })

      const { token } = tokenRequest

      // establish wss with AssemblyAI (AAI) at 16000 sample rate
      aai_socket = await new WebSocket(`wss://api.assemblyai.com/v2/realtime/ws?sample_rate=16000&token=${token}`)

      // handle incoming messages
      aai_socket.onmessage = async message => {
        const res = JSON.parse(message.data)

        if (res.message_type === "FinalTranscript" && !potentialFinalUtterance) {
          finalTranscriptText += res.text
          if (finalTranscriptText.length > 0) {
            potentialFinalUtterance = true
          }
          lastFinalTranscriptTime = res.audio_end
        }
        if (res.message_type === "PartialTranscript" && potentialFinalUtterance) {
          if (res.text === "" && res.audio_end - lastFinalTranscriptTime > 1200) {
            const frame_key = await sendFrame()
            console.log(frame_key)
            const response =
              recordingType === "module" || type === "Manager"
                ? {
                  user: curr_user,
                  message_content: finalTranscriptText,
                  convoStart: false,
                  convoEnd: false,
                  additionalInstructions: additionalInstructions,
                  assistant_id: assistant_id,
                  convoId: audioFileID,
                  order: SpeakerTurnNum.current,
                  voice: prospectName,
                  companyTag: company,
                  callType: callType,
                  FirstName: name,
                  image: frame_key,
                }
                : {
                  user: curr_user,
                  message_content: finalTranscriptText,
                  convoStart: false,
                  convoEnd: false,
                  context: conversationContext,
                  initialUserMessage: systemPrompt,
                  convoId: audioFileID,
                  order: SpeakerTurnNum.current,
                  voice: prospectName,
                  companyTag: company,
                  callType: callType,
                  FirstName: name,
                }
            const payload = { action: ws_endpoint, message: response }
            socket.send(JSON.stringify(payload))
            let message = { text: finalTranscriptText, user: "user" }
            updateTranscript(message)
            setSpeaker("bot")
            finalTranscriptText = ""
            if (aai_socket) {
              aai_socket.send(JSON.stringify({ terminate_session: true }))
              aai_socket.close()
              aai_socket = null
            }

            if (aai_recorder.current) {
              aai_recorder.current.pauseRecording()
            }
          } else if (res.text.length > 0) {
            potentialFinalUtterance = false
          }
        }
      }

      aai_socket.onerror = event => {
        console.error(event)
        aai_socket.close()
      }

      aai_socket.onclose = event => {
        console.log(event)
        aai_socket = null
      }

      aai_socket.onopen = async () => {
        // if(aai_socket){
        //   aai_socket.send(JSON.stringify({ end_utterance_silence_threshold: 3000 }))
        // }
        aai_recorder.current = new RecordRTC(UserDataStream.current, {
          type: "audio",
          mimeType: "audio/webm;codecs=pcm", // endpoint requires 16bit PCM audio
          recorderType: RecordRTC.StereoAudioRecorder,
          timeSlice: 250, // set 250 ms intervals of data that sends to AAI
          desiredSampRate: 16000,
          numberOfAudioChannels: 1, // real-time requires only one channel
          bufferSize: 4096,
          audioBitsPerSecond: 128000,
          ondataavailable: blob => {
            const reader = new FileReader()
            reader.onload = () => {
              const base64data = reader.result

              // audio data must be sent as a base64 encoded string
              if (aai_socket) {
                aai_socket.send(JSON.stringify({ audio_data: base64data.split("base64,")[1] }))
              }
            }
            reader.readAsDataURL(blob)
          },
        })
        aai_recorder.current.startRecording()
        setSpeaker("user")
      }
    }
  }

  const initialPrompt = <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
          <div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Quick reminder</h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">As an AI-powered platform, you may experience false information that’s presented as fact due to hallucinations
            associated with artificial intelligence, unusual lag times, or unresponsiveness. In the event of any such
          shortcomings, please restart the simulation and run it again.</p>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6">
            {noUserPermission ? <button type="button" class="inline-flex w-full justify-center rounded-md bg-pareBlue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pareBlue">I undertsand, let's go!</button>
              : <button type="button" onClick={startConversation} class="inline-flex w-full justify-center rounded-md bg-pareBlue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pareBlue">I undertsand, let's go!

          </button>}
          </div>
        </div>
      </div>
    </div>
  </div>

  return !convoStarted ? (
    <div className={`frostedBackground is-visible`}>
      {initialPrompt}
    </div>
  ) : (
      <div className="controlBar"></div>
    )
}

export default DictaphoneProto

import React from "react"


const RubricEditPop = ({ header, body, leftButtonLabel, rightButtonLabel, leftButtonFunction, rightButtonFunction, setCurrentRubric }) => {


  const handleRubricInputChange = (e) => {
    setCurrentRubric(e.target.value)
  }

  return (<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

        <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-6/12 ">
          <div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">{header}</h3>
              <div class="mt-2">
                <textarea class="text-sm text-gray-500 w-full h-[75vh] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-pareBlue rounded-md " onChange={handleRubricInputChange}>{body}</textarea>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button type="button" class="inline-flex w-full justify-center rounded-md bg-pareBlue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pareBlue sm:col-start-2" onClick={rightButtonFunction}>{rightButtonLabel}</button>
            <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0" onClick={leftButtonFunction}>{leftButtonLabel}</button>
          </div>
        </div>
      </div>
    </div>
  </div>)
}

export default RubricEditPop
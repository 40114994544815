import React, { useEffect, useState, useContext } from "react"
import Recordings from "../../components/Recordings/Recordings"
import { useNavigate, useLocation } from "react-router-dom"
import { grid } from "ldrs"
import "./AssignmentLandingPage.css"
import { Tabs, TabsList, TabsTrigger } from "../../@shadcn_components/ui/tabs"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../@shadcn_components/ui/breadcrumb"
import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions
} from 'mic-check';
import { NotificationContext } from "../../contexts/notificationContext"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { toJS } from "mobx"
import AssignmentLandingPageSkeleton from "../../components/AssignmentLandingPage/skeletons"
import ModuleDetails from "../../components/ActivityTeamViewPage/moduleDetails"
import ModuleBreadcrumb from "../../components/ActivityTeamViewPage/moduleBreadcrumb"


grid.register()

const AssignmentLandingPage = () => {
  const { triggerNotification } = useContext(NotificationContext)
  const { userStore, recordingListStore, moduleListStore } = useStores()
  const [attemptArray, setAttemptArray] = useState([])
  const [pageLoading, setPageLoading] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()
  const [refreshInProgress, setRefreshInProgress] = useState(false)
  const [deletePopUpMenu, setDeletePopUpMenu] = useState(false)
  const [renamePopUpMenu, setRenamePopUpMenu] = useState(false)
  const [popUpMenuDetails, setPopUpMenuDetails] = useState(null)
  const [selectedTag, setSelectedTab] = useState("Overview")
  const [moduleCatInfo, setModuleCatInfo] = useState({
    description: "",
    objective: "",
    persona_summary: "",
    scenario: "",
    rubric_name: "",
    links: "",
    duration: "",
    dueDate: "",
    numAttempts: "",
    testType: "",
  })
  const [modulePendingReview, setModulePendingReview] = useState("Not Passed")
  const managerActivityData = moduleListStore.getModuleUserSpecific(location.state.activity_id, userStore.user.userId)

  useEffect(() => {
    const fetchRecordings = async () => {
      if (!recordingListStore.userRecordings) {
        await recordingListStore.getUserRecordings(location.state.activity_id, true, userStore.user.userId)
      } else {
        await recordingListStore.getUserRecordings(location.state.activity_id, false, userStore.user.userId)
      }
    }

    const fetchModules = async () => {
      if (!moduleListStore.userModules) {
        if (userStore.user.type === "SDR" || userStore.user.type === "AE") {
          await moduleListStore.getAssignedModules(userStore.user.company, userStore.user.userId)
        } else {
          await moduleListStore.getManagerModules(userStore.user.type, userStore.user.company, userStore.user.userId)
        }
      }
    }

    const fetchData = async () => {
      try {
        // Fetch only if necessary
        await Promise.all([fetchRecordings(), fetchModules()])

        const attempts = toJS(
          recordingListStore.getAttempts(location.state.activity_id, userStore.user.userId).map(x => toJS(x)),
        )
        setAttemptArray(attempts)
        const dueDate = managerActivityData.dueDate
        setModuleCatInfo({
          description: managerActivityData.description,
          objective: managerActivityData.objective,
          persona_summary: managerActivityData.persona_summary,
          scenario: managerActivityData.scenario,
          rubric_name: managerActivityData.rubricFile,
          links: managerActivityData.links,
          duration: managerActivityData.duration,
          dueDate,
          numAttempts: attempts.length,
          testType: managerActivityData.testType,
        })

        const user_status = managerActivityData.userStatus
        setModulePendingReview(user_status)
      } catch (error) {
        console.error("Error fetching data:", error)
      } finally {
        setPageLoading(false)
      }
    }
    fetchData()
  }, [])

  const openMenuPopUp = convoObj => {
    setPopUpMenuDetails(convoObj)
    if (convoObj.popUpType === "delete") {
      setDeletePopUpMenu(true)
    } else if (convoObj.popUpType === "rename") {
      setRenamePopUpMenu(true)
    }
  }

  const checkPermissions = async () => {
    try {

      await requestMediaPermissions();
      return true

    } catch (err) {

      const { type, name, message } = err;
      if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
        // browser does not have permission to access camera or microphone
        triggerNotification("error", name, message)
        return false
      } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
        // user didn't allow app to access camera or microphone
        triggerNotification("error", name, message)
        return false
      } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
        // camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
        // (mostly Windows specific problem)
        triggerNotification("error", name, message)
        return false
      } else {
        // not all error types are handled by this library
        triggerNotification("error", name, message)
        return false
      }

    };
  }


  const startNewAttempt = async () => {
    if (await checkPermissions()) {
      const moduleObj = location.state
      navigate("/chatRoomPage", { state: moduleObj })
    }
  }

  const recordingsBoxContent = refreshInProgress ? (
    <div className="flex-1 px-8 flex flex-row content-center justify-center">
      <l-grid size="120" speed="1.4" color="#3045FF" className="mt-50" />
    </div>
  ) : !attemptArray.length ? (
    <div className="flex flex-col items-center mt-20">
      <p className="text-sm text-muted-foreground font-semibold mb-2">No Attempts</p>
      <p className="text-sm text-muted-foreground">Click "Run Sim" to get started!</p>
    </div>
  ) : (
        <div className="w-full px-6 flex flex-row flex-wrap">
          <Recordings
            recordings={attemptArray}
            name={"user"}
            handleMenuPopUp={openMenuPopUp}
            activityName={location.state.testName}
          />
        </div>
      )

  return (
    <>
      {pageLoading ? (
        <AssignmentLandingPageSkeleton></AssignmentLandingPageSkeleton>
      ) : (
          <div class="xl:pl-0 w-full">
            <main>
              <header>
                <nav class="flex overflow-x-auto border-b border-white/10 py-4">
                  <div className="px-8">
                    <ModuleBreadcrumb
                      basePath="/assignmentsPage"
                      baseLabel="Modules"
                      currentPage={location.state.testName}
                    />
                  </div>
                </nav>

                <div class="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-gray-400/10 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8">
                  <div>
                    <div class="flex items-center gap-x-3">
                      {modulePendingReview === "Incomplete" || modulePendingReview === "Pending" ? (
                        <div class="flex-none rounded-full bg-yellow_light p-1 text-yellow">
                          <div class="h-2 w-2 rounded-full bg-current"></div>
                        </div>
                      ) : modulePendingReview === "Passed" ? (
                        <div class="flex-none rounded-full bg-green_light p-1 text-green">
                          <div class="h-2 w-2 rounded-full bg-current"></div>
                        </div>
                      ) : (
                            <div class="flex-none rounded-full bg-red-400/10 p-1 text-red-400">
                              <div class="h-2 w-2 rounded-full bg-current"></div>
                            </div>
                          )}

                      <h1 class="flex gap-x-3 text-base leading-7">
                        <span class="font-semibold text-grey">{location.state.testName}</span>
                      </h1>
                    </div>
                    <p class="mt-2 text-xs leading-6 text-gray-400">{`${location.state.testName} Summary`}</p>
                  </div>
                  {modulePendingReview === "Not Passed" || modulePendingReview === "Incomplete" ? (
                    <button
                      class="order-first flex-none rounded-full bg-indigo-400/10 px-2 py-1 text-s font-medium text-pareBlue ring-1 ring-inset ring-pareBlue sm:order-none"
                      onClick={startNewAttempt}>
                      Run Sim
                  </button>
                  ) : modulePendingReview == "Pending" ? (
                    <button
                      // disabled
                      class="order-first flex-none rounded-full bg-indigo-400/10 px-2 py-1 text-s font-medium text-indigo-400 ring-1 ring-inset ring-indigo-400/30 sm:order-none"
                      onClick={checkPermissions}>
                      Pending
                  </button>
                  ) : modulePendingReview == "Passed" ? (
                    <button
                      disabled
                      class="order-first flex-none rounded-full bg-indigo-400/10 px-2 py-1 text-s font-medium text-indigo-400 ring-1 ring-inset ring-indigo-400/30 sm:order-none">
                      Passed
                  </button>
                  ) : null}
                </div>

                <div class="grid grid-cols-1 bg-gray-400/10 sm:grid-cols-2 lg:grid-cols-4">
                  <div class="border-t border-white/5 px-4 py-6 sm:px-6 lg:px-8">
                    <p class="text-sm font-medium leading-6 text-gray-400">Due Date</p>
                    <p class="mt-2 flex items-baseline gap-x-2">
                      <span class="text-4xl font-semibold tracking-tight text-grey">
                        {moduleCatInfo.dueDate || "None"}
                      </span>
                    </p>
                  </div>
                  <div class="border-t border-white/5 px-4 py-6 sm:border-l sm:px-6 lg:px-8">
                    <p class="text-sm font-medium leading-6 text-gray-400">Duration</p>
                    <p class="mt-2 flex items-baseline gap-x-2">
                      <span class="text-4xl font-semibold tracking-tight text-grey">{moduleCatInfo.duration || "0"}</span>
                      <span class="text-sm text-gray-400">mins</span>
                    </p>
                  </div>
                  <div class="border-t border-white/5 px-4 py-6 sm:px-6 lg:border-l lg:px-8">
                    <p class="text-sm font-medium leading-6 text-gray-400">Number of attempts</p>
                    <p class="mt-2 flex items-baseline gap-x-2">
                      <span class="text-4xl font-semibold tracking-tight text-grey">{moduleCatInfo.numAttempts}</span>
                    </p>
                  </div>
                  <div class="border-t border-white/5 px-4 py-6 sm:border-l sm:px-6 lg:px-8">
                    <p class="text-sm font-medium leading-6 text-gray-400">Status</p>
                    <p class="mt-2 flex items-baseline gap-x-2">
                      <span class="text-4xl font-semibold tracking-tight text-grey">{modulePendingReview}</span>
                    </p>
                  </div>
                </div>
              </header>
              <div className="mb-4 mt-4 px-6">
                <Tabs defaultValue="Overview" className="w-[100%]" onValueChange={setSelectedTab}>
                  <TabsList>
                    <TabsTrigger value="Overview">Overview</TabsTrigger>
                    <TabsTrigger value="Attempts">Attempts</TabsTrigger>
                  </TabsList>
                </Tabs>
              </div>
              {selectedTag === "Overview" ? (
                <div class="px-8 ">
                  <ModuleDetails moduleCatInfo={moduleCatInfo} />
                </div>
              ) : (
                  <>{recordingsBoxContent}</>
                )}
            </main>
          </div>
        )}
    </>
  )
}

export default observer(AssignmentLandingPage)

import { APIQueryBuilder, GenericAPIRequest } from "./helpers"
import {
  GetProfilePictureFromS3Response,
  GetTeamProfilePicturesResponse,
  GetUserInfoResponse,
  GetSalesTeamResponse,
  GetModuleListResponse,
  GetRecordingListResponse,
  GetLearningPathsResponse,
  GenericResponseError,
  GenericNoDataReturnedResponse,
} from "./api.types"
import { LearningPathFieldsSnapshotIn } from "../models/lp-store/lp-store"
import { ModuleListFieldsSnapshotIn } from "../models/module-store/module-store";

export async function getUserInfo(): Promise<GetUserInfoResponse> {
  try {
    const res = await APIQueryBuilder("/getUserInfo")
    return { kind: "ok", data: res.userInfo }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getProfilePictureFromS3(user_id: string): Promise<GetProfilePictureFromS3Response> {
  try {
    const res = await APIQueryBuilder("/getProfilePicture", { user_id })
    return { kind: "ok", data: res }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getTeamProfilePictures(userIds, user_id: string): Promise<GetTeamProfilePicturesResponse> {
  try {
    const stringyfiedUserIds = userIds.join(",")
    const res = await APIQueryBuilder("/getTeamProfilePictures", { stringyfiedUserIds, user_id })
    return { kind: "ok", data: res }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getVideo(recordings, user_id: string): Promise<GetProfilePictureFromS3Response> {
  try {
    const res = await APIQueryBuilder("/getVideo", { recordings, user_id })
    return { kind: "ok", data: res }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getSalesTeam(managerId, userType, company, user_id): Promise<GetSalesTeamResponse> {
  try {
    const res = await APIQueryBuilder("/getTeamData", { userType, company, user_id })
    const team = res.teamList
    const inviteeList = res.inviteeList

    return { kind: "ok", data: { managerId, team, inviteeList } }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getAssignedModulesInfo(company, user_id): Promise<GetModuleListResponse> {
  try {
    const res = await APIQueryBuilder("/getAssignedModules", { company, user_id })
    return { kind: "ok", data: res.involvedActivities }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getManagerModulesInfo(userType, company, user_id): Promise<GetModuleListResponse> {
  try {
    const res = await APIQueryBuilder("/getManagerActivities", { userType, company, user_id })

    return { kind: "ok", data: res.managerActivities }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getUserRecordingInfo(activityId, user_id): Promise<GetRecordingListResponse> {
  try {
    const res = await APIQueryBuilder("/getUserRecordings", { activityId, user_id })
    return { kind: "ok", data: res }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getModuleAttemptsInfo(activityId: string, user_id: string): Promise<GetRecordingListResponse> {
  try {
    const res = await APIQueryBuilder("/getModuleAttempts", { activityId, user_id })
    return { kind: "ok", data: res }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function GetLearningPaths(
  companyId: string,
  user_id: string,
): Promise<GetLearningPathsResponse | GenericResponseError> {
  try {
    const res = await GenericAPIRequest(
      "ZenoApp",
      "get",
      "/learningPath/{companyId}",
      { companyId },
      { user_id: user_id },
    )
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error fetching learning paths:", e)
    return { kind: "error", error: e }
  }
}

export async function GetLearningPath(
  companyId: string,
  learningPathId: string,
  user_id: string,
): Promise<GetLearningPathsResponse | GenericResponseError> {
  try {
    const res = await GenericAPIRequest(
      "ZenoApp",
      "get",
      "/learningPath/{companyId}/{learningPathId}",
      { companyId, learningPathId },
      { user_id },
    )
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error fetching learning path:", e)
    return { kind: "error", error: e }
  }
}

export async function CreateLearningPath(
  learningPath: Partial<LearningPathFieldsSnapshotIn>,
): Promise<GetLearningPathsResponse | GenericResponseError> {
  try {
    const res = await GenericAPIRequest("ZenoApp", "post", "/learningPath", {}, {}, learningPath)
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error creating learning path:", e)
    return { kind: "error", error: e }
  }
}

export async function UpdateLearningPath(
  companyId: string,
  learningPathId: string,
  updatedFields: Partial<LearningPathFieldsSnapshotIn>,
): Promise<GetLearningPathsResponse | GenericResponseError> {
  try {
    const res = await GenericAPIRequest(
      "ZenoApp",
      "put",
      "/learningPath/{companyId}/{learningPathId}",
      { companyId, learningPathId },
      {},
      updatedFields,
    )
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error updating learning path:", e)
    return { kind: "error", error: e }
  }
}

export async function DeleteLearningPath(
  companyId: string,
  learningPathId: string,
  user_id: string,
): Promise<GenericNoDataReturnedResponse | GenericResponseError> {
  try {
    await GenericAPIRequest(
      "ZenoApp",
      "delete",
      "/learningPath/{companyId}/{learningPathId}",
      { companyId, learningPathId },
      {},
      { user_id },
    )
    return { kind: "ok", data: null }
  } catch (e) {
    console.error("Error deleting learning path:", e)
    return { kind: "error", error: e }
  }
}

export async function UpdateModule(
  manager_id: string,
  activity_id: string,
  updatedFields: Partial<ModuleListFieldsSnapshotIn>,
): Promise<GenericNoDataReturnedResponse | GenericResponseError> {
  try {
    const res = await GenericAPIRequest(
      "ZenoApp",
      "put",
      "/modules/{manager_id}/{activity_id}",
      { manager_id, activity_id },
      {},
      updatedFields,
    )
    return { kind: "ok", data: res }
  } catch (e) {
    console.error("Error updating module:", e)
    return { kind: "error", error: e }
  }
}

import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/20/solid'

export default function Alert({ type = 'error', title, messages = [] }) {
  const getIconAndColor = () => {
    switch (type) {
      case 'success':
        return { icon: CheckCircleIcon, bgColor: 'bg-green-50', textColor: 'text-green-800', iconColor: 'text-green-400' };
      case 'error':
      default:
        return { icon: XCircleIcon, bgColor: 'bg-red-50', textColor: 'text-red-800', iconColor: 'text-red-400' };
    }
  };

  const { icon: Icon, bgColor, textColor, iconColor } = getIconAndColor();

  return (
    <div className={`rounded-md ${bgColor} p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon aria-hidden="true" className={`h-5 w-5 ${iconColor}`} />
        </div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium ${textColor}`}>{title}</h3>
          {messages.length > 0 && (
            <div className={`mt-2 text-sm ${textColor}`}>
              <ul role="list" className="list-disc space-y-1 pl-5">
                {messages.map((message, index) => (
                  <li key={index}>{message}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// HomePage.jsx
import React, { useEffect, useState } from "react"
import { Auth, API } from "aws-amplify"
import ScatterPlot from "../../components/Homedash/scatter"
import BarChartComparison from "../../components/Homedash/compBarChart"
import RepVelocityChart from "../../components/Homedash/veloChart"
import MiniGraph from "../../components/Homedash/miniChart"
import Leaderboard from "../../components/Homedash/leaderboard"
import HomePageSkeleton from "../../components/Homedash/skeletons"
import DropdownSelector from "../../components/LearningCenter/dropdown"
import ChecklistDrawer from "../../components/Homedash/checklistDrawer"
import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbPage } from "../../@shadcn_components/ui/breadcrumb"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import ChecklistAlert from "../../components/Homedash/checklistAlert"

const formatToDollar = value =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  }).format(value)

const HomePage = observer(() => {
  const { userStore } = useStores()
  const userId = userStore.user.userId
  const email = userStore.user.userEmail
  const name = userStore.user.name
  const userType = userStore.user.type
  const managerEmail = userType === "Manager" ? email : userStore.user.managerEmail
  const isAdminOrManager = userType === "Admin" || userType === "Manager"

  // State variables
  const [isLoading, setIsLoading] = useState(true)
  const [checklistData, setChecklistData] = useState(null)
  const [isChecklistOpen, setIsChecklistOpen] = useState(false)

  // Team data state variables
  const [orgSalesforceData, setOrgSalesforceData] = useState([])
  const [userCompetencyProfileScores, setUserCompetencyProfileScores] = useState({})
  const [averageCompetencyProfileScores, setAverageCompetencyProfileScores] = useState({})
  const [rviHistory, setRviHistory] = useState([])
  const [averageRVI, setAverageRVI] = useState()
  const [oppsWonHistory, setOppsWonHistory] = useState([])
  const [totalOppsWon, setTotalOppsWon] = useState()
  const [oppSizeHistory, setOppSizeHistory] = useState([])
  const [averageOppSize, setAverageOppSize] = useState()
  const [winRateHistory, setWinRateHistory] = useState([])
  const [averageWinRate, setAverageWinRate] = useState()
  const [dealCycleHistory, setDealCycleHistory] = useState([])
  const [averageDealCycle, setAverageDealCycle] = useState()

  // Individual user data state variables for overlay
  const [userRviHistory, setUserRviHistory] = useState([])
  const [userAverageRVI, setUserAverageRVI] = useState()
  const [userOppsWonHistory, setUserOppsWonHistory] = useState([])
  const [userTotalOppsWon, setUserTotalOppsWon] = useState()
  const [userOppSizeHistory, setUserOppSizeHistory] = useState([])
  const [userAverageOppSize, setUserAverageOppSize] = useState()
  const [userWinRateHistory, setUserWinRateHistory] = useState([])
  const [userAverageWinRate, setUserAverageWinRate] = useState()
  const [userDealCycleHistory, setUserDealCycleHistory] = useState([])
  const [userAverageDealCycle, setUserAverageDealCycle] = useState()

  // Manager dropdown control (Admin only)
  const [managers, setManagers] = useState([])
  const [selectedManager, setSelectedManager] = useState(null)

  // User dropdown control (all users)
  const [teamUsers, setTeamUsers] = useState([])
  const [selectedUserEmail, setSelectedUserEmail] = useState(null)

  // Fetch data for a team
  const fetchData = async (topPerformerEmail, managerEmail, type) => {
    try {
      console.log(`Fetching team-wide historical data for managerEmail: ${managerEmail}`)
      const data = await API.get("DataIntegration", "/getHomePageInfo", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        queryStringParameters: { email: topPerformerEmail, managerEmail: managerEmail, userType: type, user_id: userId },
      })

      // Set team data
      setOrgSalesforceData(data.teamSalesforceData)
      setUserCompetencyProfileScores(data.topPerformerCompProfile.competencyScores)
      setAverageCompetencyProfileScores(data.teamCompScores)
      setRviHistory(data.teamRVIHistory.items)
      setAverageRVI(data.teamRVIHistory.aggregatedValue)
      setOppsWonHistory(data.teamOppsWon.items)
      setTotalOppsWon(data.teamOppsWon.aggregatedValue)
      setOppSizeHistory(data.teamAvgDealSize.items)
      setAverageOppSize(data.teamAvgDealSize.aggregatedValue)
      setWinRateHistory(data.teamWinRate.items)
      setAverageWinRate(data.teamWinRate.aggregatedValue)
      setDealCycleHistory(data.teamDealCycle.items)
      setAverageDealCycle(data.teamDealCycle.aggregatedValue)
    } catch (error) {
      console.error("Error fetching team data:", error)
    }
  }

  // Fetch data for an individual user
  const fetchUserData = async userEmail => {
    try {
      console.log(`Fetching individual historical data for ${userEmail}`)
      const data = await API.get("DataIntegration", "/userAnalyzedData", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        queryStringParameters: { email: userEmail, user_id:userId },
      })

      // Set individual user data
      setUserRviHistory(data.userRVIHistory.items)
      setUserAverageRVI(data.userRVIHistory.aggregatedValue)
      setUserOppsWonHistory(data.userOppsWon.items)
      setUserTotalOppsWon(data.userOppsWon.aggregatedValue)
      setUserOppSizeHistory(data.userAvgDealSize.items)
      setUserAverageOppSize(data.userAvgDealSize.aggregatedValue)
      setUserWinRateHistory(data.userWinRate.items)
      setUserAverageWinRate(data.userWinRate.aggregatedValue)
      setUserDealCycleHistory(data.userDealCycle.items)
      setUserAverageDealCycle(data.userDealCycle.aggregatedValue)
    } catch (error) {
      console.error("Error fetching user data:", error)
    }
  }

  // Fetch checklist data
  const fetchChecklist = async () => {
    try {
      const checklistInfo = await API.get("DataIntegration", "/checklistInfo", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        queryStringParameters: {
          userId: userId,
          userType: userType,
          user_id:userId
        },
      })

      // Set checklist data based on userType
      if (userType === "Admin") {
        const tasks = [
          {
            id: 1,
            name: "Set up Call Intelligence Integration",
            status: checklistInfo.hasCallIntelligence,
            href: "/settings",
          },
          {
            id: 2,
            name: "Set up CRM Integration",
            status: checklistInfo.hasCrm,
            href: "/settings",
          },
          {
            id: 3,
            name: "Invite a team manager to the platform",
            status: checklistInfo.hasInvitedManager,
            href: "/managerTeam",
          },
        ]
        setChecklistData(tasks)
      } else if (userType === "Manager") {
        const tasks = [
          {
            id: 1,
            name: "Invite team members to the platform",
            status: checklistInfo.hasInvitedUsers,
            href: "/managerTeam",
          },
          // Add other manager-specific tasks if any
        ]
        setChecklistData(tasks)
      }
    } catch (error) {
      console.error("Error fetching checklist data:", error)
    }
  }

  // Fetch all managers (Admin only)
  const fetchManagers = async () => {
    try {
      const managersData = await API.get("DataIntegration", "/managerEmails", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        queryStringParameters:{
          user_id: userId
        }
      })
      const validManagerEmails = managersData.managerEmails.filter(email => email)
      console.log(`Valid managers: ${validManagerEmails}`)
      setManagers(validManagerEmails)
      return validManagerEmails
    } catch (error) {
      console.error("Error fetching managers:", error)
      return []
    }
  }

  // Fetch users under a manager
  const fetchTeamUsers = async managerEmailToUseForQuery => {
    try {
      const data = await API.get("DataIntegration", "/userEmailsUnderManager", {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
        queryStringParameters: { managerEmail: managerEmailToUseForQuery, user_id:userId },
      })
      console.log(`Setting team users to ${data.userEmails}`)
      setTeamUsers(data.userEmails || [])
      setSelectedUserEmail(null)
    } catch (error) {
      console.error("Error fetching team users:", error)
    }
  }

  // Initialize data based on user type
  useEffect(() => {
    if (!userStore.user) {
      return
    }

    const initialize = async () => {
      setIsLoading(true)

      const email = userStore.user.userEmail
      const userType = userStore.user.type
      const managerEmail = userType === "Manager" ? email : userStore.user.managerEmail

      console.log("Initializing HomePage", { email, userType, managerEmail })
      // Fetch checklist
      await fetchChecklist()

      let managerToUse = null

      if (userType === "Admin") {
        const managersList = await fetchManagers()
        if (managersList && managersList.length > 0) {
          setManagers(managersList)
          managerToUse = managersList[0]
          setSelectedManager(managerToUse)
        } else {
          console.warn("No managers available.")
          setIsLoading(false)
          return
        }
      } else if (userType === "Manager") {
        managerToUse = email
        setSelectedManager(managerToUse)
      } else {
        managerToUse = managerEmail
        setSelectedManager(managerToUse)
      }

      // Fetch team data and users
      await fetchData(email, managerToUse, userType)
      await fetchTeamUsers(managerToUse)

      setIsLoading(false)
    }

    initialize()
  }, [userStore.user])

  // Handle manager change (Admin only)
  const handleManagerChange = async event => {
    const newManagerEmail = event.target.value
    setSelectedManager(newManagerEmail)
    setIsLoading(true)

    // Fetch team data and users for the new manager
    await fetchData(newManagerEmail, newManagerEmail, userType)
    await fetchTeamUsers(newManagerEmail)

    // Clear selected user overlay
    setSelectedUserEmail(null)
    setUserRviHistory([])
    setUserAverageRVI(null)
    setUserOppsWonHistory([])
    setUserTotalOppsWon(null)
    setUserOppSizeHistory([])
    setUserAverageOppSize(null)
    setUserWinRateHistory([])
    setUserAverageWinRate(null)
    setUserDealCycleHistory([])
    setUserAverageDealCycle(null)

    setIsLoading(false)
  }

  // Handle user selection change
  const handleUserChange = async event => {
    const userEmail = event.target.value
    if (userEmail === "") {
      setSelectedUserEmail(null)
      // Clear user data overlay
      setUserRviHistory([])
      setUserAverageRVI(null)
      setUserOppsWonHistory([])
      setUserTotalOppsWon(null)
      setUserOppSizeHistory([])
      setUserAverageOppSize(null)
      setUserWinRateHistory([])
      setUserAverageWinRate(null)
      setUserDealCycleHistory([])
      setUserAverageDealCycle(null)
    } else {
      setSelectedUserEmail(userEmail)
      await fetchUserData(userEmail)
    }
  }

  const handleOpenChecklist = () => {
    setIsChecklistOpen(true)
  }

  if (isLoading) {
    return <HomePageSkeleton />
  }

  // Prepare options for the user dropdown
  const userOptions = [{ label: "None", value: "" }, ...teamUsers.map(user => ({ label: user, value: user }))]

  return (
    <div className="w-full h-full p-8">
      {/* Breadcrumb */}
      <div className="mb-8">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbPage>Home</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>

      {/* Checklist Drawer */}
      {isChecklistOpen && (
        <ChecklistDrawer isOpen={isChecklistOpen} setIsOpen={setIsChecklistOpen} checklistData={checklistData} />
      )}

      {/* Checklist Alert */}
      {checklistData && checklistData.some(task => task.status !== "Complete") && (
        <div className="mb-4">
          <ChecklistAlert onDetailsClick={handleOpenChecklist} />
        </div>
      )}

      {/* User Greeting */}
      <div className="sm:flex sm:items-center mb-8">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">{`Hello, ${name} 👋`}</h1>
          <p className="mt-2 text-sm text-gray-700">See what’s happening and where to spend your time</p>
        </div>

        <div className="sm:flex sm:items-center space-x-4">
          {/* ADMIN ONLY manager select dropdown */}
          {userType === "Admin" && (
            <DropdownSelector
              label="Select Manager"
              options={managers.map(managerEmail => ({ label: managerEmail, value: managerEmail }))}
              value={selectedManager || ""}
              onChange={handleManagerChange}
            />
          )}

          {/* User Selection Dropdown */}
          {teamUsers.length > 0 && (
            <DropdownSelector
              label="Select User"
              options={userOptions}
              value={selectedUserEmail || ""}
              onChange={handleUserChange}
            />
          )}
        </div>
      </div>

      {/* Main Content */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        {/* Left column (2/3 width) */}
        <div className="lg:col-span-2 space-y-4">
          {/* Scatter Plot */}
          <div className="bg-white p-4 shadow rounded-lg">
            <h2 className="text-lg font-semibold">Productivity Distribution</h2>
            <p className="mb-2 text-sm text-gray-700">Efficiency Index (EI) / Total Activity</p>
            <ScatterPlot data={orgSalesforceData} selectedUserEmail={selectedUserEmail} />
          </div>
          {/* Team Competency Bar Chart */}
          <div className="bg-white p-4 shadow rounded-lg">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">Team Competency Tracking</h2>
              <a href="/learningCenterPage" className="text-blue-600 text-sm font-semibold hover:underline">
                Go to {isAdminOrManager ? "Coaching" : "Learning"} Center →
              </a>
            </div>
            <BarChartComparison
              userCompetencyScores={userCompetencyProfileScores}
              averageCompetencyScores={averageCompetencyProfileScores.ae} // TODO: flex this
            />
          </div>
        </div>

        {/* Right column (1/3 width) */}
        <div className="lg:col-span-1 space-y-4">
          {/* Team Rev Efficiency Chart */}
          <div className="bg-white p-4 shadow rounded-lg">
            <h2 className="text-lg font-semibold">Team Average Revenue Efficiency</h2>
            <p className="mb-2 text-md text-gray-700">{formatToDollar(averageRVI)}</p>
            <RepVelocityChart data={rviHistory} userData={selectedUserEmail ? userRviHistory : null} />
          </div>

          {/* Mini charts grid */}
          <div className="grid grid-cols-2 gap-4 lg:grid-cols-2 sm:grid-cols-1">
            {/* Closed Opps */}
            <div className="bg-white p-4 shadow rounded-lg">
              <h2 className="text-md font-semibold">Closed Opps</h2>
              <p className="mb-2 text-md text-gray-700">{totalOppsWon}</p>
              <MiniGraph
                data={oppsWonHistory}
                userData={selectedUserEmail ? userOppsWonHistory : null}
                label={"Weekly Closed"}
                fieldKey={"value"}
              />
            </div>

            {/* Avg Deal Size */}
            <div className="bg-white p-4 shadow rounded-lg">
              <h2 className="text-md font-semibold">Avg Deal Size</h2>
              <p className="mb-2 text-md text-gray-700">{formatToDollar(averageOppSize)}</p>
              <MiniGraph
                data={oppSizeHistory}
                userData={selectedUserEmail ? userOppSizeHistory : null}
                label={"Avg"}
                fieldKey={"value"}
              />
            </div>

            {/* Win Rate */}
            <div className="bg-white p-4 shadow rounded-lg">
              <h2 className="text-md font-semibold">Win Rate</h2>
              <p className="mb-2 text-md text-gray-700">{averageWinRate}%</p>
              <MiniGraph
                data={winRateHistory}
                userData={selectedUserEmail ? userWinRateHistory : null}
                label={"Win Rate"}
                fieldKey={"value"}
              />
            </div>

            {/* Deal Cycle */}
            <div className="bg-white p-4 shadow rounded-lg">
              <h2 className="text-md font-semibold">Deal Cycle</h2>
              <p className="mb-2 text-md text-gray-700">{averageDealCycle} days</p>
              <MiniGraph
                data={dealCycleHistory}
                userData={selectedUserEmail ? userDealCycleHistory : null}
                label={"Time (days)"}
                fieldKey={"value"}
              />
            </div>
          </div>

          {/* Leaderboard */}
          <div className="bg-white p-4 shadow rounded-lg">
            <h2 className="text-lg font-semibold">Efficiency Leaderboard</h2>
            <Leaderboard people={orgSalesforceData} />
          </div>
        </div>
      </div>
    </div>
  )
})

export default HomePage

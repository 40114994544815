"use client"

import { Link, useNavigate } from "react-router-dom"
import { Popover, PopoverButton, PopoverBackdrop, PopoverPanel } from "@headlessui/react"
import clsx from "clsx"
import { ArrowRightIcon } from '@heroicons/react/20/solid'
import { Container } from "./Container"
import { NavLink } from "./NavLink"
import paresvg from "../images/logos/parelogo.svg"

function MobileNavLink({ href, children }) {
  return (
    <PopoverButton as="button" className="block w-full p-2">
      <a href={href}>{children}</a>
    </PopoverButton>
  )
}

function MobileNavIcon({ open }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round">
      <path d="M0 1H14M0 7H14M0 13H14" className={clsx("origin-center transition", open && "scale-90 opacity-0")} />
      <path d="M2 2L12 12M12 2L2 12" className={clsx("origin-center transition", !open && "scale-90 opacity-0")} />
    </svg>
  )
}

function MobileNavigation() {
  return (
    <Popover>
      <PopoverButton
        className="relative z-10 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none"
        aria-label="Toggle Navigation">
        {({ open }) => <MobileNavIcon open={open} />}
      </PopoverButton>
      <PopoverBackdrop
        transition
        className="fixed inset-0 bg-slate-300/50 duration-150 data-[closed]:opacity-0 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <PopoverPanel
        transition
        className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5 data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-150 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in">
        <MobileNavLink href="https://app.sprinto.com/trust-center/view/d2bac724-48fa-4ac4-9ef3-697ebe38cd0a">Security</MobileNavLink>
        <MobileNavLink href="mailto:hello@pare-ai.com">Contact us</MobileNavLink>
        <hr className="m-2 border-slate-300/40" />
        <MobileNavLink href="/signin">Sign in</MobileNavLink>
      </PopoverPanel>
    </Popover>
  )
}

export function Header() {
  const navigate = useNavigate()
  return (
    <header className="py-10">
      <Container>
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center md:gap-x-12">
            <Link to="#" aria-label="Home">
              <img src={paresvg} alt={"pare_logo"} />
            </Link>
            <div className="hidden md:flex md:gap-x-6">
              <NavLink href="https://app.sprinto.com/trust-center/view/d2bac724-48fa-4ac4-9ef3-697ebe38cd0a">Security</NavLink>
              <NavLink href="mailto:hello@pare-ai.com">Contact us</NavLink>
              {/* <NavLink href="#pricing">Pricing</NavLink> */}
            </div>
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            <button
              onClick={() => navigate("/signin")}
              type="button"
              className="inline-flex items-center gap-x-2 rounded-md bg-gray-300 px-3.5 py-2.5 text-sm font-semibold text-black shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-300"
            >
              Sign in
              <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
            </button>
            <button
              onClick={() => navigate("/waitlistRequestPage")}
              type="button"
              className="inline-flex items-center gap-x-2 rounded-md bg-pareBlue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#1c34cc] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pareBlue"
            >
              Request access today
              <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
            </button>
            <div className="-mr-1 md:hidden">
              <MobileNavigation />
            </div>
          </div>
        </nav>
      </Container>
    </header>
  )
}

"use client"

import { useState, useEffect } from "react"
import { Transition } from "@headlessui/react"
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline"
import { XMarkIcon } from "@heroicons/react/20/solid"

const Notification = ({
  show,
  setShow,
  type = "success",
  title,
  message,
  duration = 5000, // Default duration is 5000 milliseconds
}) => {
  const iconStyles = {
    success: { Icon: CheckCircleIcon, color: "text-green-400" },
    error: { Icon: ExclamationCircleIcon, color: "text-red-400" },
  }

  const { Icon, color } = iconStyles[type] || iconStyles.success

  useEffect(() => {
    let timeout
    if (show && duration > 0) {
      timeout = setTimeout(() => {
        setShow(false)
      }, duration)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [show, setShow, duration])

  return (
    <>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50">
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={show}
            enter="transform transition duration-300 ease-out"
            enterFrom="translate-y-2 opacity-0 sm:translate-x-2 sm:translate-y-0"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition duration-100 ease-in"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black/5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="shrink-0">
                    <Icon aria-hidden="true" className={`h-6 w-6 ${color}`} />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{title}</p>
                    <p className="mt-1 text-sm text-gray-500">{message}</p>
                  </div>
                  <div className="ml-4 flex shrink-0">
                    <button
                      type="button"
                      onClick={() => setShow(false)}
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">Close</span>
                      <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}

export default Notification

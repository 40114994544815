import React from "react"
import Avatar from "../Global/avatar"

const ModuleLeaderboardEntry = ({ teamMember, index }) => {

  return (
    <tr key={teamMember.email} class={`group w-full ${index % 2 == 0 ? "bg-gray-100" : ""}`}>
      <td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0" >
        <div class="flex items-center">
          <div class="h-11 w-11 flex-shrink-0">
            <Avatar name={teamMember.name} profilePicture={teamMember.profilePicture} size="lg" />
          </div>
          <div class="ml-4">
            <div class="font-medium text-gray-900">{teamMember.name}</div>
            <div class="mt-1 text-gray-500">{teamMember.email}</div>
          </div>
        </div>
      </td>
      <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        {teamMember.attemptsNum}
      </td>
      <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        {teamMember.overallScore}
      </td>
      <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-900 font-medium">
        {teamMember.highestScore}
      </td>
      <td class="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0"></td>

    </tr>
  )
}

export default ModuleLeaderboardEntry